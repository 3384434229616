import React, { useState, useEffect } from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import tableHelpers from '../helpers/TableHelpers.js'
import RichTable from '../components/richTable/RichTable.jsx'
import TwoStepConfirmationButton from '../components/TwoStepConfirmButton.js'
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '../components/Tooltip.js'

function AutomateTrades(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const [brokerageAccount, setBrokerageAccount] = useState(null);
    const [accountSettings, setAccountSettings] = useState(null);
    const [planPositions, setPlanPosiitons] = useState(null);
    const [tradeResults, setTradeResults] = useState(null);
    const [instructions, setInstructions] = useState([]);
    const [marketOrders, setMarketOrders] = useState(false);
    const [shouldAllocateCash, setShouldAllocateCash] = useState(false);
    const [updates, setUpdates] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isPlacingOrders, setIsPlacingOrders] = useState(false);
    const [showPreciseShares, setShowPreciseShares] = useState(false);
    const [maxExposure, setMaxExposure] = useState(null);

    useEffect(() => {
        (async function () {
            setBrokerageAccount(null);
            setIsLoading(true);

            let settingsUrl = `/api/accounts?userId=` + props.userId;
            let accounts = await (await fetch(settingsUrl)).json();
            let account = null;
            if (accounts && accounts.length) {
                account = accounts.find(a => a.idaccounts === Number(props.accountId));
                if (account) {
                    setAccountSettings(account);
                    setMaxExposure(account.max_trading_exposure);
                }
            }

            const url = "/api/brokerageAccounts?userId=" + props.userId + "&brokerage=schwab"
            const brokerageAccounts = await (await fetch(url)).json();
            console.log("brokerageAccounts in AutomateTrade", brokerageAccounts);
            if (brokerageAccounts.success && brokerageAccounts.accounts) {
                let brokerageAccount = brokerageAccounts.accounts.find(act => act.accountId === Number(props.accountId));
                if (brokerageAccount) {
                    console.log("Found brokerage account", brokerageAccount);
                    setBrokerageAccount(brokerageAccount);

                    console.log("Loading positions for " + brokerageAccount.planId);
                    let url = `/api/positions?planId=` + brokerageAccount.planId;
                    if (account && account.max_trading_exposure) url += "&maxExposure=" + account.max_trading_exposure
                    let positions = await (await fetch(url)).json();
                    if (positions) {
                        console.log("positions", positions);
                        setPlanPosiitons(positions);
                    }
                } else {
                    console.log("Could not find brokerage account for " + props.accountId)
                    setBrokerageAccount(null);
                }
            }
            setIsLoading(false);

        })();
    }, [props.userId, props.accountId, updates]);

    useEffect(() => {
        (async function () {
            if (props.isOpen)
                setModalOpen(true);
        })();
    }, [props.isOpen]);

    const handleCloseModal = () => {
        setModalOpen(false);
        setTradeResults(null);
        setInstructions([]);
        if (props.onClose)
            props.onClose();
    }

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: props.mobileView ? 425 : 475,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 2,
    };

    function updateInstruction(symbol, shares) {
        let numShares = parseInt(shares);
        console.log("Updating " + symbol + " to " + shares, numShares)
        if (numShares === null || numShares === 0 || isNaN(numShares)) {
            console.log("removing", symbol, shares);
            let temp = JSON.parse(JSON.stringify(instructions));
            temp = temp.filter(ins => ins.symbol !== symbol);
            console.log(temp);
            setInstructions(temp);
        } else {
            let temp = JSON.parse(JSON.stringify(instructions));
            let instruction = temp.find(ins => ins.symbol === symbol);
            if (instruction) {
                instruction.shares = numShares;
            } else {
                temp.push({
                    symbol: symbol,
                    shares: numShares,
                    isOption: symbol.length > 6
                })
            }
            console.log(temp);
            setInstructions(temp);
        }
    }

    function instructionFormatter(cell, row) {
        if (row.securityType === "EQUITY" || row.securityType === "OPTION") {
            let instruction = instructions.find(i => i.symbol === row.symbol);
            let instructionShares = instruction ? Number(instruction.shares) : 0;
            return <div className='sameline'>
                <input size={5} style={{ height: "18px" }} value={instructionShares ? instructionShares : ""} onChange={(e) => updateInstruction(row.symbol, e.target.value)} />
                &nbsp;&nbsp;<div style={{ fontSize: "20px" }}><Link onClick={() => setSymbolInstructionsForRebalacne(row.symbol)}>🔀</Link> <Link onClick={() => setSymbolInstructionsForLiquidate(row.symbol)}>⏹️</Link>&nbsp;<Link onClick={() => updateInstruction(row.symbol, "")}>🆑</Link></div>
            </div>
        } else return null;
    }

    async function applyMaxExposure() {

        console.log("applyMaxExposure", maxExposure);

        const backtestUrl = `/api/lastBacktestResults?planId=` + brokerageAccount.planId + "&liveTradesOnly=1";
        let backtest = await (await fetch(backtestUrl)).json();
        console.log("lastBacktest", backtest);

        let drawdown = backtest.returns.find(r => r.name === "Current Drawdown");
        let currentDrawdown = drawdown ? drawdown.result : 0

        let updateUrl = `/api/accountMaxExposure?userId=` + props.userId + `&accountId=` + props.accountId + `&maxExposure=` + Number(maxExposure) + "&currentDrawdown=" + currentDrawdown.toFixed(2);
        await (await fetch(updateUrl, { method: 'post' })).json();

        let url = `/api/positions?planId=` + brokerageAccount.planId;
        url += `&maxExposure=` + maxExposure;
        let positions = await (await fetch(url)).json();
        if (positions) {
            console.log("positions", positions);
            setPlanPosiitons(positions);
        }
    }

    function symbolFormatter(cell, row) {
        if (row.openAmount === 0) {
            return cell;
        } else {
            return (
                <>
                    {cell}<Tooltip text={" ✳️"}>
                        OrderId: {row.openOrderId}
                        <br></br>
                        Open Shares: {row.openAmount}
                        <br></br>
                        Limit Price: {row.openPrice}
                        <br></br>
                        Stop Price: {row.openStopPrice}
                        <br></br>
                        <br></br>
                        <button class="btn btn-danger" onClick={() => onChaseOrder(row.openOrderId)} type="button">Chase Order</button>
                        <br></br>
                        <br></br>
                        <button class="btn btn-danger" onClick={() => onCancelOrder(row.openOrderId)} type="button">Cancel Order</button>
                    </Tooltip>
                </>
            );
        }
    }

    async function onPlaceTrades() {
        setTradeResults(null);
        setIsPlacingOrders(true);

        const url = "/api/brokerageTrades?brokerage=schwab&userId=" + props.userId + "&accountId=" + props.accountId + "&marketOrders=" + marketOrders + "&shouldAllocateCash=" + shouldAllocateCash
        let tradesPayload = instructions;
        let result = await (await fetch(url, { method: 'post', body: JSON.stringify(tradesPayload) })).json();

        setIsPlacingOrders(false);
        setInstructions([]);
        setTradeResults(result);
        setUpdates(updates + 1);
        console.log(result);
    }

    async function onCancelOrder(orderId) {
        setTradeResults(null);
        setIsPlacingOrders(true);

        const url = "/api/brokerageOrderCancel?brokerage=schwab&userId=" + props.userId + "&accountId=" + props.accountId + "&orderId=" + orderId
        let result = await (await fetch(url, { method: 'post' })).json();
        console.log("order cancel response", result);

        setIsPlacingOrders(false);
        setInstructions([]);
        setTradeResults(result);
        setUpdates(updates + 1);
        console.log(result);
    }

    async function onChaseOrder(orderId) {
        setTradeResults(null);
        setIsPlacingOrders(true);

        const url = "/api/brokerageOrderChase?brokerage=schwab&userId=" + props.userId + "&accountId=" + props.accountId + "&orderId=" + orderId
        let result = await (await fetch(url, { method: 'post' })).json();
        console.log("order modify response", result);

        setIsPlacingOrders(false);
        setInstructions([]);
        setTradeResults(result);
        setUpdates(updates + 1);
        console.log(result);
    }

    async function setInstructionsFromPropTrades() {
        if (props.trades) {
            console.log("Setting instructions from trades")
            let newInstructions = [];
            props.trades.forEach(trade => {
                let shares = getSharesForRebalance(trade.symbol);
                if (shares) {
                    newInstructions.push({
                        symbol: trade.symbol,
                        shares: shares
                    })
                }
            })
            setInstructions(newInstructions);
        }
    }

    async function setInstructionsForLiquidate() {
        let positions = getPositions();
        let temp = [];
        positions.forEach(pos => {
            if (pos.securityType === "EQUITY" && pos.shares) {
                temp.push({
                    symbol: pos.symbol,
                    shares: pos.shares * -1
                })
            }
        })
        setInstructions(temp);
    }

    async function setInstructionsForRebalance() {
        let positions = getPositions();
        let temp = [];
        let brokerageValue = brokerageAccount ? brokerageAccount.value : 0;
        positions.forEach(pos => {
            if (pos.securityType === "EQUITY") {
                let planPosition = planPositions ? planPositions.find(pp => pp.netSymbol === pos.symbol) : null;
                if (planPosition) {
                    let allocation = showPreciseShares ? planPosition.precisePercentage : planPosition.netPositionIncludingGainLoss;
                    let shares = Math.round((brokerageValue * (allocation / 100) / pos.price) - pos.shares);
                    if (shares) {
                        temp.push({
                            symbol: pos.symbol,
                            shares: shares
                        })
                    }
                } else {
                    temp.push({
                        symbol: pos.symbol,
                        shares: pos.shares * -1
                    })
                }
            }
        })
        console.log(temp);
        setInstructions(temp);
    }

    function getSharesForRebalance(symbol) {
        let positions = getPositions();
        let shares = 0;
        let brokerageValue = brokerageAccount ? brokerageAccount.value : 0;
        positions.forEach(pos => {
            if (pos.symbol === symbol) {
                let planPosition = planPositions ? planPositions.find(pp => pp.netSymbol === pos.symbol) : null;
                if (planPosition) {
                    let allocation = showPreciseShares ? planPosition.precisePercentage : planPosition.netPositionIncludingGainLoss;
                    shares = Math.round((brokerageValue * (allocation / 100) / pos.price) - pos.shares);
                }
            }
        })
        return shares;
    }


    async function setSymbolInstructionsForRebalacne(symbol) {
        let positions = getPositions();
        let temp = JSON.parse(JSON.stringify(instructions.filter(ins => ins.symbol !== symbol)));
        let brokerageValue = brokerageAccount ? brokerageAccount.value : 0;
        positions.forEach(pos => {
            if (pos.symbol === symbol) {
                let planPosition = planPositions ? planPositions.find(pp => pp.netSymbol === pos.symbol) : null;
                if (planPosition) {
                    let allocation = showPreciseShares ? planPosition.precisePercentage : planPosition.netPositionIncludingGainLoss;
                    let shares = Math.round((brokerageValue * (allocation / 100) / pos.price) - pos.shares);
                    console.log("shares", shares, brokerageValue, pos);
                    if (shares) {
                        temp.push({
                            symbol: pos.symbol,
                            shares: shares
                        })
                    }
                } else {
                    temp.push({
                        symbol: pos.symbol,
                        shares: pos.shares * -1
                    })
                }
            }
        })
        setInstructions(temp);
    }

    function setSymbolInstructionsForLiquidate(symbol) {
        let positions = getPositions();
        let temp = JSON.parse(JSON.stringify(instructions.filter(ins => ins.symbol !== symbol)));
        positions.forEach(pos => {
            if (pos.symbol === symbol && pos.shares) {
                temp.push({
                    symbol: pos.symbol,
                    shares: pos.shares * -1
                })
            }
        })
        setInstructions(temp);
    }

    function getPositions() {
        if (brokerageAccount) {
            let all = [];
            brokerageAccount.positions.forEach(position => {
                let planPosition = planPositions ? planPositions.find(pp => pp.netSymbol === position.symbol) : null;
                let instruction = instructions.find(i => i.symbol === position.symbol);
                let instructionShares = instruction ? Number(instruction.shares) : 0;
                let openOrder = brokerageAccount.openOrders ? brokerageAccount.openOrders.find(order => order.symbol === position.symbol) : null;
                all.push({
                    symbol: position.symbol,
                    shares: position.shares,
                    planPercent: planPosition ? planPosition.netPositionIncludingGainLoss : undefined,
                    exposure: planPosition && planPosition.netExposure ? planPosition.netExposure : 0,
                    precisePlanPercent: planPosition ? planPosition.precisePercentage : undefined,
                    percent: position.marketValue / brokerageAccount.value * 100,
                    instructions: instructionShares,
                    price: position.marketValue / position.shares,
                    resultPercent: (position.marketValue / position.shares) * (position.shares + instructionShares) / brokerageAccount.value * 100,
                    securityType: position.securityType,
                    openAmount: openOrder ? openOrder.shares : 0,
                    openPrice: openOrder ? openOrder.price : 0,
                    openStopPrice: openOrder ? openOrder.stopPrice : 0,
                    openOrderId: openOrder ? openOrder.orderId : undefined
                });
            })
            planPositions && planPositions.forEach(pos => {
                if (!all.find(p => p.symbol === pos.netSymbol)) {
                    let trade = props.trades ? props.trades.find(t => t.symbol === pos.netSymbol) : undefined;
                    let instruction = instructions.find(i => i.symbol === pos.netSymbol);
                    let instructionShares = instruction ? Number(instruction.shares) : 0;
                    let planPosition = planPositions.find(pp => pp.netSymbol === pos.netSymbol);
                    all.push({
                        symbol: pos.netSymbol,
                        shares: 0,
                        planPercent: pos.netPositionIncludingGainLoss,
                        precisePlanPercent: planPosition ? planPosition.precisePercentage : 0,
                        exposure: planPosition.netExposure ? planPosition.netExposure : 0,
                        percent: 0,
                        price: pos.currentPrice,
                        instructions: trade ? trade.shares : null,
                        resultPercent: pos.currentPrice * instructionShares / brokerageAccount.value * 100,
                        securityType: "EQUITY",
                        openAmount: 0,
                        openPrice: 0,
                        openStopPrice: 0
                    });
                }
            })
            return all;
        } else {
            return [];
        }
    }

    let positions = getPositions();
    const totalExposure = positions ? positions.reduce((accumulator, pos) => accumulator + pos.exposure, 0) : 0;

    return (
        <>
            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalOpen}
                onClose={handleCloseModal}
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Automate Trades
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {!isLoading ?
                            <>
                                {brokerageAccount ?
                                    <>
                                        <input type='checkbox' checked={showPreciseShares} onClick={() => setShowPreciseShares(!showPreciseShares) & setUpdates(updates + 1)} /> Use Precise Shares
                                        <br></br>
                                        Max Exposure: <input type="text" value={maxExposure} onChange={(e) => setMaxExposure(e.target.value)} size={4} />&nbsp;<Link onClick={() => applyMaxExposure()}>Apply</Link> {accountSettings && accountSettings.max_exposure_drawdown ? "(Set at " + tableHelpers.percentFormatterOneDecimal(accountSettings.max_exposure_drawdown, null) + " DD)" : null}
                                        <br></br>
                                        <RichTable data={positions} className="table-striped table-hover table-condensed"
                                            mappers={[
                                                { title: `Symbol`, field: 'symbol', formatter: symbolFormatter, isDefaultSort: true },
                                                { title: `Schwab Shares`, field: 'shares', formatter: tableHelpers.intFormatter, hidden: props.mobileView, width: 30 },
                                                { title: `Schwab`, field: 'percent', formatter: tableHelpers.percentFormatterOneDecimal, width: 30 },
                                                { title: 'Adj Plan', field: (showPreciseShares ? 'precisePlanPercent' : 'planPercent'), formatter: tableHelpers.percentFormatterOneDecimal, width: 30 },
                                                { title: `Trade`, field: 'instruction', formatter: instructionFormatter },
                                                { title: `Result`, field: 'resultPercent', formatter: tableHelpers.percentFormatterOneDecimal, width: 30 },
                                            ]}
                                        />
                                        <Link onClick={() => setInstructionsFromPropTrades()}>Todays Trades</Link> | <Link onClick={() => setInstructionsForRebalance()}>Rebalance</Link> | <Link onClick={() => setInstructionsForLiquidate()}>Liquidate</Link> | <Link onClick={() => setInstructions([])}>Clear All</Link>
                                        <br></br>
                                        <br></br>
                                        Plan Exposure: {tableHelpers.percentFormatterNoDecimals(totalExposure)}
                                        <br></br>
                                        Account Value: {brokerageAccount ? tableHelpers.moneyFormatterNoCents(brokerageAccount.value, null) : null} ({tableHelpers.percentChangeFormatter(brokerageAccount.todaysChange)})
                                        <br></br>
                                        Cash: {brokerageAccount ? tableHelpers.moneyFormatterNoCents(brokerageAccount.cash, null) : null} ({brokerageAccount ? (brokerageAccount.cash / brokerageAccount.value * 100).toFixed(1) : "N/A"}%)
                                        <br></br>
                                        ✳️ Open Orders: {brokerageAccount && brokerageAccount.openOrders ? brokerageAccount.openOrders.length : null}

                                        <div style={{ textAlign: 'right' }}>
                                            {instructions.length ?
                                                <TwoStepConfirmationButton id={0} text="Place Trades" onConfirmed={onPlaceTrades} >
                                                    <ul>
                                                        {instructions.map(ins => {
                                                            return <li>{(ins.shares >= 0 ? "BUY " : "SELL ") + Math.abs(ins.shares) + " of " + ins.symbol}</li>
                                                        })}
                                                    </ul>
                                                    <input type="checkbox" checked={marketOrders} onClick={() => setMarketOrders(!marketOrders)} /> Market day orders
                                                    <br></br>
                                                    <input type="checkbox" checked={shouldAllocateCash} onClick={() => setShouldAllocateCash(!shouldAllocateCash)} /> Allocate CASH to SGOV
                                                    <br></br>
                                                </TwoStepConfirmationButton>
                                                :
                                                null}
                                        </div>
                                        <br></br>
                                        <div style={{ textAlign: 'right' }}>
                                            <button class="btn btn-secondary" onClick={handleCloseModal} type="button">Cancel</button>
                                        </div>
                                        <br></br>
                                        <Link onClick={() => setUpdates(updates + 1)}>Reload</Link>
                                    </>
                                    :
                                    <>Could not find brokerage account at {props.brokerage}</>}
                            </>
                            :
                            <CircularProgress />
                        }
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <b>
                            {isPlacingOrders ? <><br></br><LinearProgress /></> : null}
                            {tradeResults ? (tradeResults.error ? tradeResults.error : tradeResults.orders.length + " orders placed") : null}
                        </b>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}

export default AutomateTrades;