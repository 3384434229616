import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import tableHelpers from '../helpers/TableHelpers.js'
import { Chart } from "react-google-charts";
import RichTable from '../components/richTable/RichTable.jsx'
import { createDateSorter } from '../components/richTable/sorters.js'
import ExpectancyChart from '../components/ExpectancyChart.js';
import AlgoQuickStats from '../components/AlgoQuickStats.js'
import Tooltip from '../components/Tooltip.js'
import EditTrade from './EditTrade.js';

function ActiveTrades(props) {
    const [data, setData] = useState([]);
    const [isEditingTrade, setIsEditingTrade] = useState(false);
    const [editTrade, setEditTrade] = useState(null)
    const [updates, setUpdates] = useState(0);

    useEffect(() => {
        (function () {

            let url = "";
            if (props.planId && props.planId !== "") {
                if (props.onlyShowUserTrades)
                    url = `/api/activeUserTrades?planId=` + props.planId + `&userId=` + props.userId + `&accountId=` + props.accountId
                else
                    url = `/api/activeTrades/?planId=` + props.planId + `&userId=` + props.userId + `&accountId=` + props.accountId
            } else {
                url = `/api/allActiveTrades`;
            }

            fetch(url)
                .then(res => res.json())
                .then(text => {
                    console.log("activeTrades", text);
                    setData(text);
                });

        })();
    }, [props.planId, props.userId, props.accountId, props.onlyShowUserTrades, updates]);

    function getQuote(trade) {
        return trade.current_price;
    }

    function getProfit(trade) {
        var quote = trade.current_price;
        if (quote && trade.close) {
            return ((quote - trade.close) / trade.close * 100);
        } else
            return 0;
    }

    function getDollarProfit(trade) {
        var quote = trade.current_price;
        if (quote && trade.close) {
            return (quote - trade.close);
        } else
            return 0;
    }

    function getProfitImpact(trade) {
        return getProfit(trade) * trade.allocation / 100 * (trade.percent_of_allocation / 100);
    }

    function getTotalProfit() {
        let total = 0.0;
        data.forEach(trade => {
            var quote = trade.current_price;
            if (quote && trade.close) {
                const profit = ((quote - trade.close) / trade.close * 100) *
                    trade.allocation / 100 * (trade.percent_of_allocation / 100);
                total += profit;
            }
        })
        return total;
    }

    function getTrades() {
        return data.map(trade => {
            let userProfit = (trade.user_sell_price - trade.user_purchase_price) / trade.user_purchase_price * 100;
            let userAllocation = (trade.user_amount * trade.user_purchase_price * 100) / props.accountValue * 100
            return {
                idplan_trades: trade.idplan_trades,
                purchase_date: trade.purchase_date,
                display_name: trade.display_name,
                algoId: trade.algoId,
                trade_symbol: trade.trade_symbol,
                algo_type: trade.algo_type,
                close: trade.user_purchase_price ? trade.user_purchase_price : trade.close,
                quote: trade.user_sell_price ? trade.user_sell_price : getQuote(trade),
                profit: getProfit(trade),
                allocation: trade.user_trade_is_option ? userAllocation : trade.allocation * (trade.percent_of_allocation / 100),
                netProfit: trade.user_trade_is_option ? userProfit / userAllocation / 100 : getProfitImpact(trade),
                dollarProfit: props.accountValue ? props.accountValue * (getProfitImpact(trade) / 100) : getDollarProfit(trade),
                winning_percentage: trade.winning_percentage,
                avg_duration: trade.avg_duration,
                avg_profit: trade.avg_profit,
                avg_win: trade.avg_win,
                avg_loss: trade.avg_loss,
                robustness_overall: trade.robustness_overall,
                user_symbol: trade.user_symbol,
                user_trade_is_option: trade.user_trade_is_option,
                user_amount: trade.user_amount,
                user_purchase_price: trade.user_purchase_price,
                user_sell_price: trade.user_sell_price,
                user_trade_id: trade.user_trade_id,
                user_profit: isNaN(userProfit) ? undefined : userProfit
            }
        });
    }

    function algoLinkFormatter(cell, row) {
        let algoid = row.algoId;
        return (
            <>
                <Link className="bg-transparent" to={"/algo/" + algoid}>{cell}</Link>
                &nbsp;&nbsp;<Tooltip textClass={"tooltip-link"} text={"🔍"} width={225} borderWidth={"0px"} position="right center" >
                    <center>
                        <AlgoQuickStats winningPercentage={row.winning_percentage}
                            avgProfit={row.avg_profit}
                            avgWin={row.avg_win}
                            avgLoss={row.avg_loss}
                            avgDuration={row.avg_duration}
                            robustness={row.robustness_overall}
                            algoType={row.algo_type.toUpperCase()}
                        />
                    </center>
                </Tooltip >
            </>

        )
    }

    function getStats() {

        let stats = { count: 0, winners: 0, losers: 0, avgProfit: 0.0, avgNetProfit: 0.0, avgWin: 0.0, avgLoss: 0.0, maxWin: 0.0, maxLoss: 0.0, netProfit: 0.0 }

        getTrades().forEach(trade => {
            let profit = trade.user_profit ? trade.user_profit : trade.profit;
            let netProfit = trade.netProfit;
            stats.avgProfit = ((stats.avgProfit * stats.count) + profit) / (stats.count + 1);
            stats.avgNetProfit = ((stats.avgNetProfit * stats.count) + netProfit) / (stats.count + 1);

            if (profit > 0) {
                if (profit > stats.maxWin) stats.maxWin = profit;
                stats.avgWin = ((stats.avgWin * stats.winners) + profit) / (stats.winners + 1);
                ++stats.winners;
            } else if (profit < 0) {
                if (profit < stats.maxLoss) stats.maxLoss = profit;
                stats.avgLoss = ((stats.avgLoss * stats.losers) + profit) / (stats.losers + 1);
                ++stats.losers;
            }
            ++stats.count;
        });
        stats.winningPct = (stats.winners / (stats.count) * 100);

        return [{
            netProfit: getTotalProfit(),
            dollarProfit: getTotalProfit() / 100 * props.accountValue,
            winners: stats.winners,
            losers: stats.losers,
            count: stats.count,
            avgProfit: stats.avgProfit,
            avgNetProfit: stats.avgNetProfit,
            avgWin: stats.avgWin,
            avgLoss: stats.avgLoss,
            maxWin: stats.maxWin,
            maxLoss: stats.maxLoss,
            winningPct: stats.winningPct
        }]
    }

    function getChartData() {
        let chartData = [];
        let data = getTrades();
        data.forEach(trade => {
            const profit = trade.user_profit ? trade.user_profit : trade.profit;
            chartData.push([trade.display_name + "(" + trade.trade_symbol + ")", profit / 100, profit >= 0 ? "#B2D8B2" : "#B85D5D"])
        });
        chartData.sort((a, b) => {
            return a[1] - b[1];
        })

        if (chartData.length)
            chartData.unshift(['Algo', 'P/L', { role: "style" }]);

        return chartData;
    }

    function getBubbleChartData() {
        let bubbleChartData = [["Id", "Days", "Profit", "Algo", "Allocation"]]
        const trades = getTrades();
        trades.forEach(trade => {
            const profit = trade.user_profit ? trade.user_profit / 100 : trade.profit / 100;
            let days = Math.round((new Date() - new Date(trade.purchase_date)) / (1000 * 60 * 60 * 24));
            bubbleChartData.push(["", days, profit, trade.display_name, trade.allocation])
        })
        return bubbleChartData;
    }

    function showEditModal(trade) {
        console.log("Opening modal for ", trade);
        setIsEditingTrade(true);
        setEditTrade(trade)
    }

    function symbolFormatter(cell, row) {
        if (row.user_symbol)
            return <>
                {row.user_symbol} <Link className="bg-transparent" onClick={() => showEditModal(row)}>✏️</Link>
            </>
        else
            return <Link id="link" className="bg-transparent" to={`/stock/${cell}`}>{cell}</Link>;
    }

    function symbolAlgoFormatter(cell, row) {
        let algoid = row.algoId;
        if (row.user_symbol) {
            return (
                <>
                    <Link className="bg-transparent" to={"/algo/" + algoid}>{row.user_symbol}</Link>  <Link className="bg-transparent" onClick={() => showEditModal(row)}>✏️</Link>
                </>
            );
        } else {
            return (
                <>
                    <Link id="link" className="bg-transparent" to={`/stock/${row.trade_symbol}`}>{row.trade_symbol}</Link>
                    &nbsp;<Link className="bg-transparent" to={"/algo/" + algoid}>{row.display_name}</Link>
                    &nbsp;<Tooltip textClass={"tooltip-link"} text={"🔍"} width={225} borderWidth={"0px"} position="right center" >
                        <center>
                            <AlgoQuickStats winningPercentage={row.winning_percentage}
                                avgProfit={row.avg_profit}
                                avgWin={row.avg_win}
                                avgLoss={row.avg_loss}
                                avgDuration={row.avg_duration}
                                robustness={row.robustness_overall}
                                algoType={row.algo_type.toUpperCase()}
                            />
                        </center>
                    </Tooltip >
                </>
            )
        }
    }

    const chartData = getChartData();
    const max = tableHelpers.getMaxChartValue(chartData, 1);
    const min = tableHelpers.getMinChartValue(chartData, 1);
    var chartOptions = {
        title: "P/L by Trade", legend: { position: 'none' },
        vAxis: {
            format: '#%',
            viewWindowMode: 'explicit',
            viewWindow: {
                max: max + Math.abs(max) * .1,
                min: min - Math.abs(min) * .1
            },
        },
        bar: { width: "25" },
    }

    function profitChartFormatter(cell, row) {
        return <ExpectancyChart width={250} profit={row.profit} avgWin={row.avg_win} avgLoss={row.avg_loss} />
    }

    function mobileProfitChartFormatter(cell, row) {
        if (row.user_profit != null)
            return tableHelpers.percentChangeFormatter(row.user_profit);
        return <ExpectancyChart width={250} profit={row.profit} avgWin={row.avg_win} avgLoss={row.avg_loss} />
    }

    function profitFormatter(cell, row) {
        if (row.user_trade_is_option) {
            return tableHelpers.percentChangeFormatter(row.user_profit, null);
        } else {
            return tableHelpers.percentChangeFormatter(cell, null);
        }
    }

    function onStopEditingTrade() {
        console.log("onStopEditingTrade");
        setIsEditingTrade(false)
        setEditTrade(null);
        setUpdates(updates + 1);
    }

    function purchaseDateFormatter(cell, row) {
        return (
            <>
                <Link to={"/tradeDetails/" + row.idplan_trades}>{tableHelpers.dateFormmatter(cell, row)}</Link>
            </>
        )
    }

    const statsMapper = [
        { title: `Count`, field: 'count', formatter: tableHelpers.intFormatter },
        { title: `Win %`, field: 'winningPct', formatter: tableHelpers.percentFormatter },
        { title: `Net`, field: 'netProfit', formatter: tableHelpers.percentChangeFormatter },
        { title: `Avg P/L`, field: 'avgProfit', formatter: tableHelpers.percentChangeFormatter },
        { title: `Avg Net P/L`, field: 'avgNetProfit', formatter: tableHelpers.percentChangeFormatter, hidden: props.mobileView },
        { title: `Avg Win`, field: 'avgWin', formatter: tableHelpers.percentChangeFormatter, hidden: props.mobileView },
        { title: `Avg Loss`, field: 'avgLoss', formatter: tableHelpers.percentChangeFormatter, hidden: props.mobileView },
        { title: `Max Win`, field: 'maxWin', formatter: tableHelpers.percentChangeFormatter, hidden: props.mobileView },
        { title: `Max Loss`, field: 'maxLoss', formatter: tableHelpers.percentChangeFormatter, hidden: props.mobileView },
    ]

    const tradesMapper = [
        { title: `Purch Date`, field: 'purchase_date', formatter: purchaseDateFormatter, sorters: createDateSorter('purchase_date'), hidden: props.mobileView, isDefaultSort: true },
        { title: `Symbol`, field: 'trade_symbol', sorters: true, formatter: symbolFormatter },
        { title: `Algo`, field: 'display_name', sorters: true, formatter: algoLinkFormatter },
        { title: `P/L`, field: 'profit', sorters: true, formatter: profitFormatter },
        { title: `Expectancy`, field: 'profit', sorters: true, formatter: profitChartFormatter, hidden: props.mobileView || !(props.planId && props.planId !== ""), width: 275 },
        { title: `Allocation`, field: 'allocation', sorters: true, formatter: tableHelpers.percentFormatterNoDecimals, hidden: !(props.planId && props.planId !== "") },
        { title: `Net Profit`, field: 'netProfit', sorters: true, formatter: tableHelpers.percentChangeFormatter, hidden: props.mobileView || !(props.planId && props.planId !== "") },
        { title: `Type`, field: 'algo_type', sorters: true, hidden: props.mobileView },
        { title: `Cost`, field: 'close', sorters: true, formatter: tableHelpers.moneyFormatter, hidden: props.mobileView },
        { title: `Current Price`, field: 'quote', sorters: true, formatter: tableHelpers.moneyFormatter, hidden: props.mobileView },
    ]

    const mobileTradesMapper = [
        { title: `Algo`, field: 'trade_symbol', sorters: true, formatter: symbolAlgoFormatter },
        { title: `P/L vs. Expectancy`, field: 'profit', sorters: true, formatter: mobileProfitChartFormatter, width: 250 },
        { title: `Allocation`, field: 'allocation', sorters: true, formatter: tableHelpers.percentFormatterNoDecimals, hidden: !(props.planId && props.planId !== "") },
    ]

    return (
        <>
            <br></br>
            <RichTable data={getStats()} mappers={statsMapper} className="table-striped table-hover table-condensed" />

            {chartData.length && !props.hideCharts ?
                <table width="100%">
                    <tr>
                        <td width="50%">
                            <Chart
                                chartType="ColumnChart"
                                data={chartData}
                                height={"300px"}
                                width={"100%"}
                                options={chartOptions}
                                chartPackages={["corechart", "controls"]}
                            />
                        </td>
                        <td>
                            <Chart
                                chartType="BubbleChart"
                                data={getBubbleChartData()}
                                width={"100%"}
                                options={{
                                    hAxis: { title: "Days Open" },
                                    vAxis: { title: "Profit %", format: 'percent' },
                                    bubble: { textStyle: { fontSize: 0 } },
                                }}
                                chartPackages={["corechart", "controls"]}
                            />
                        </td>
                    </tr>
                </table >


                :
                null
            }

            <div style={{ width: '100%' }}>
                {props.mobileView ?
                    <RichTable data={getTrades()} mappers={mobileTradesMapper} className="table-striped table-hover table-condensed" />
                    :
                    <RichTable data={getTrades()} mappers={tradesMapper} className="table-striped table-hover table-condensed" />
                }
            </div >

            <EditTrade isOpen={isEditingTrade && editTrade}
                onClose={onStopEditingTrade}
                userTradeId={editTrade ? editTrade.user_trade_id : null}
            />
        </>
    );
}

export default ActiveTrades;