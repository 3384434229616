import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import Tooltip from '../components/Tooltip.js';
import SingleAlgoRobustness from './SingleAlgoRobustness.js';

function AlgoRobustness(props) {
    const [data, setData] = useState([]);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        (function () {
            fetch(`/api/planRobustness?planId=` + props.planId + (showAll ? "&showAll=1" : ""))
                .then(res => res.json())
                .then(text => {
                    setData(text);
                });
        })();
    }, [props.planId, showAll]);

    function getSccores() {
        return data.map(score => {

            return {
                overall: (score.live_vs_backtest + score.recent_vs_longterm + score.oos + score.monte_carlo) / 4,
                ...score
            }
        })
    }

    function getStats() {
        let results = { totalScore: 0, count: 0, totalWeightedScore: 0, totalLong: 0, totalShort: 0, longCount: 0, shortCount: 0, average: 0, weightedAverage: 0, totalWeights: 0, longAverage: 0, shortAverage: 0 };
        data.forEach(s => {
            let avg = (s.live_vs_backtest + s.recent_vs_longterm + s.oos + s.monte_carlo) / 4;
            results.totalScore += avg;
            results.totalWeightedScore += avg * s.percentage;
            results.totalWeights += s.percentage;
            if (s.short) {
                results.totalShort += avg;
                ++results.shortCount;
            } else {
                results.totalLong += avg;
                ++results.longCount;
            }
            results.count++;
        })

        results.average = results.totalScore / results.count;
        results.longAverage = results.totalLong / results.longCount;
        results.shortAverage = results.totalShort / results.shortCount;
        results.weightedAverage = results.totalWeightedScore / results.totalWeights;

        return [results];
    }

    function algoLinkFormatter(cell, row) {
        let algoid = row.algoId;
        return (
            <>
                <Link className="bg-transparent" to={"/algo/" + algoid}>{cell}</Link>
                &nbsp;&nbsp;<Tooltip width={'500px'} textClass="tooltip-link smaller-text" text="🔍">
                    <SingleAlgoRobustness algoId={algoid} />
                </Tooltip >
            </>

        )
    }

    function liveVsBacktestFormatter(cell, row) {
        return <Tooltip width="600px" text={tableHelpers.intFormatter(cell, row)} json={row.details.liveVsBacktest} />
    }
    function recentVsLongtermFormatter(cell, row) {
        return <Tooltip width="600px" text={tableHelpers.intFormatter(cell, row)} json={row.details.recentVsLongterm} />
    }
    function oosFormatter(cell, row) {
        return <Tooltip width="600px" text={tableHelpers.intFormatter(cell, row)} json={row.details.oos} />
    }
    function monteCarloFormatter(cell, row) {
        return <Tooltip width="600px" text={tableHelpers.intFormatter(cell, row)} json={row.details.monteCarlo} />
    }

    return (
        <>
            <br></br>

            <Link id="managealgos" className="bg-transparent" to={"/managealgos"}>Update Algos</Link>
            <br></br>
            <input type='checkbox' checked={showAll} onClick={() => setShowAll(!showAll)} />Show All Algos<br></br>

            <RichTable data={getStats()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Average', field: 'average', formatter: tableHelpers.percentFormatter },
                { title: 'Weighted Avg', field: 'weightedAverage', formatter: tableHelpers.percentFormatter },
                { title: 'Long Avg', field: 'longAverage', formatter: tableHelpers.percentFormatter },
                { title: 'Short Avg', field: 'shortAverage', formatter: tableHelpers.percentFormatter },
            ]} />

            <RichTable data={getSccores()} className="table-striped table-hover table-condensed" mappers={[
                { title: 'Algo', field: 'display_name', formatter: algoLinkFormatter, sorters: true, filter: 'text' },
                { title: 'Symbol', field: 'trade_symbol', sorters: true, filter: 'text' },
                { title: 'Type', field: 'algo_type', sorters: true, filter: 'choice', hidden: props.mobileView },
                { title: 'Allocation', field: 'percentage', formatter: tableHelpers.percentFormatterNoDecimals, sorters: true },
                { title: 'Overall', field: 'overall', formatter: tableHelpers.decimalFormatter, sorters: true },
                { title: 'Live Trades', field: 'live_vs_backtest', formatter: liveVsBacktestFormatter, sorters: true, hidden: props.mobileView },
                { title: 'Recency', field: 'recent_vs_longterm', formatter: recentVsLongtermFormatter, sorters: true, hidden: props.mobileView },
                { title: 'Of of Sample', field: 'oos', formatter: oosFormatter, sorters: true, hidden: props.mobileView },
                { title: 'Monte Carlo', field: 'monte_carlo', formatter: monteCarloFormatter, sorters: true, hidden: props.mobileView },
            ]} />

            <br></br>
            <br></br>
        </>
    );
}

export default AlgoRobustness;