import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';

function DailyProfitChart(props) {

    const [startDate, setStartDate] = useState(0);
    const [days, setDays] = useState(22);
    const [dateForPositions, setDateForPositions] = useState(null);
    const [dailyPositions, setDailyPositions] = useState(null);
    const [secondaryPositions, setSecondaryPositions] = useState(null);

    useEffect(() => {
        (async function () {
            setDailyPositions(null);
            setSecondaryPositions(null);
            console.log("Plan id changed", props.planId);
        })();
    }, [props.planId]);

    function moveStartDate(diff) {
        setStartDate(startDate + diff);
    }

    function getSecondaryProfit(date) {
        if (props.secondaryResults && props.secondaryResults.dailyResults) {
            let day = props.secondaryResults.dailyResults.findIndex(r => r.date === date);
            if (day >= 0) {
                return (props.secondaryResults.dailyResults[day].total - props.secondaryResults.dailyResults[day - 1].total) / props.secondaryResults.dailyResults[day - 1].total;
            } else {
                return null;
            }
        } else
            return null;
    }

    function getSecondaryCumulativeProfit(startDate, date) {
        if (props.secondaryResults && props.secondaryResults.dailyResults) {
            let day = props.secondaryResults.dailyResults.findIndex(r => r.date === date);
            let startDay = props.secondaryResults.dailyResults.findIndex(r => r.date === startDate);
            if (day >= 0 && startDay >= 0) {
                return (props.secondaryResults.dailyResults[day].total - props.secondaryResults.dailyResults[startDay].total) / props.secondaryResults.dailyResults[startDay].total;
            } else {
                return null;
            }
        } else
            return null;
    }

    function getChartData() {
        if (props.backtestResults && props.backtestResults.dailyResults && props.backtestResults.dailyResults.length > 1) {

            let columns = ["Date", "Plan", "Cumulative",
                { id: "totalPlan", role: "annotation", type: "string" },
                "S&P 500", "S&P 500 Cumulative",
                { id: "totalSPY", role: "annotation", type: "string", style: { color: 'black' } }
            ];
            if (props.secondaryResults) {
                columns.push("Backtest");
                columns.push("Backtest Cumulative");
            }
            let result = [columns];
            let startDaysAgo = Math.min(days, props.backtestResults.dailyResults.length - startDate - 1);
            let count = 0;
            const startIndex = props.backtestResults.dailyResults.length - startDaysAgo - startDate;
            let firstDay = props.backtestResults.dailyResults[startIndex - 1]

            for (let i = startIndex; i < props.backtestResults.dailyResults.length && count < days; i++) {
                let day = props.backtestResults.dailyResults[i];
                let yesterday = props.backtestResults.dailyResults[i - 1];
                let profit = (day.total - yesterday.total) / yesterday.total;
                let spyProfit = (day.baseline - yesterday.baseline) / yesterday.baseline;
                let cumulativeProfit = (day.total - firstDay.total) / firstDay.total;
                let spyCumulativeProfit = (day.baseline - firstDay.baseline) / firstDay.baseline;
                let d = new Date(day.date);
                let annotation = null;
                let spyAnnotation = null;
                if (i === props.backtestResults.dailyResults.length - 1 || count === days - 1) {
                    annotation = (cumulativeProfit * 100).toFixed(1) + "%";
                    spyAnnotation = (spyCumulativeProfit * 100).toFixed(1) + "%";
                }
                let dateString = (d.getUTCMonth() + 1) + "/" + d.getDate() + "/" + d.getUTCFullYear()
                let dailyResult = [dateString, profit, cumulativeProfit, annotation, spyProfit, spyCumulativeProfit, spyAnnotation]
                if (props.secondaryResults) {
                    let secondaryProfit = getSecondaryProfit(day.date);
                    let secondaryCumulativeProfit = getSecondaryCumulativeProfit(firstDay.date, day.date);
                    dailyResult.push(secondaryProfit);
                    dailyResult.push(secondaryCumulativeProfit);
                }
                result.push(dailyResult);
                ++count;
            }

            return result;

        } else
            return null;
    }

    async function loadPositionsForDate(dataTable, index, planId) {
        if (chartData && chartData.length > index) {
            let day = dataTable.getValue(index, 0);
            if (day !== dateForPositions) {
                console.log("Loading positions for " + planId + " on " + day);
                setDateForPositions(day);
                const url = `/api/positionsOnDate?planId=` + planId + `&date=` + day;
                let p = await (await fetch(url)).json();
                console.log(index, day, chartData, p)
                if (p && p.length)
                    p.sort((a, b) => { return a.trade_symbol.localeCompare(b.trade_symbol) });
                setDailyPositions(p);

                const u = `/api/backtestPositionsOnDate?planId=` + planId + `&date=` + day;
                let backtestPositions = await (await fetch(u)).json();
                if (backtestPositions && backtestPositions.length) {
                    backtestPositions.sort((a, b) => {
                        return a.trade_symbol.localeCompare(b.trade_symbol);
                    });
                }
                setSecondaryPositions(backtestPositions);
            }
        }
    }

    var options = {
        chartArea: { 'width': '80%', 'height': '75%' },
        legend: { position: 'none' },
        vAxis: {
            format: 'percent'
        },
        seriesType: "bars",
        colors: props.secondaryResults ? ["blue", "blue", "grey", "grey", "red", "red"] : ["blue", "blue", "grey", "grey"],
        series: {
            1: { type: "line" },
            3: { type: "line" },
            5: { type: "line" },
        },
        annotations: {
            textStyle: {
                bold: true,
            },
        }
    }

    let chartData = getChartData();

    const chartEvents = [
        {
            eventName: "select",
            callback({ chartWrapper }) {
                console.log("Select callback", chartWrapper);
                if (chartWrapper.getChart().getSelection() && chartWrapper.getChart().getSelection().length === 1) {
                    let row = chartWrapper.getChart().getSelection()[0].row;
                    let dataTable = chartWrapper.getDataTable();
                    loadPositionsForDate(dataTable, row, props.planId);
                } else {
                    console.warn(chartWrapper.getChart().getSelection());
                }
            },
        }
    ];

    function renderAllocations(positions, allocationField) {
        const symbols = [...new Set(positions.map(pos => pos.trade_symbol))];
        console.log("symbols", symbols);

        return symbols.map(sym => {
            let total = 0;
            positions.filter(pos => pos.trade_symbol === sym).forEach(pos => total += pos[allocationField]);
            return <li>{total}% of {sym}</li>
        })
    }

    return (
        <div>
            {
                props.backtestResults && props.backtestResults.dailyResults && props.backtestResults.dailyResults.length ?
                    <div>
                        <Chart
                            chartType="ComboChart"
                            data={chartData}
                            width={props.width ? props.width : "100%"}
                            height={props.height ? props.height : "400px"}
                            options={options}
                            chartPackages={["corechart", "controls"]}
                            chartEvents={props.secondaryResults ? chartEvents : null}
                        />
                        <center>
                            <div className='sameline'>
                                <Link onClick={() => moveStartDate(22)}>⏮️</Link>   <Link onClick={() => moveStartDate(1)}>⏪ Back</Link>

                                <Dropdown width="150" onSelect={(e) => setDays(e)}>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                        {days} Days
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu  >
                                        <Dropdown.Item as='a' eventKey={5} value={5}>5 days</Dropdown.Item>
                                        <Dropdown.Item as='g' eventKey={10} value={10}>10 days</Dropdown.Item>
                                        <Dropdown.Item as='b' eventKey={22} value={22}>22 days</Dropdown.Item>
                                        <Dropdown.Item as='e' eventKey={44} value={44}>44 days</Dropdown.Item>
                                        <Dropdown.Item as='c' eventKey={66} value={60}>66 days</Dropdown.Item>
                                        <Dropdown.Item as='d' eventKey={125} value={125}>125 days</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Link onClick={() => moveStartDate(-1)}>Forward ⏩</Link>    <Link onClick={() => moveStartDate(-22)}>⏭️</Link>
                            </div>
                        </center>
                        <center>
                            <div>
                                <br></br>
                                {dailyPositions ?
                                    <table style={{ borderWidth: 1, borderStyle: 'solid' }}>
                                        <tr style={{ backgroundColor: 'transparent' }}>
                                            <td><b>Live</b> ({dateForPositions})</td>
                                            <td><b>Backtest</b></td>
                                        </tr>
                                        <tr style={{ backgroundColor: 'transparent' }}>
                                            {dailyPositions ?
                                                <td valign='top'>
                                                    <ul>
                                                        {renderAllocations(dailyPositions, "allocation")}
                                                    </ul>
                                                </td> : null}

                                            {secondaryPositions ?
                                                <td valign='top'>
                                                    <ul>
                                                        {renderAllocations(secondaryPositions, "percentage")}
                                                    </ul>
                                                </td> : null}
                                        </tr>
                                        <tr style={{ backgroundColor: 'transparent' }}>
                                            {dailyPositions ?
                                                <td valign='top'>
                                                    <ul>
                                                        {dailyPositions.map(pos => {
                                                            return (
                                                                <li>{pos.allocation}% of {pos.trade_symbol} <Link to={"/algo/" + pos.algoId}>{pos.display_name}</Link></li>
                                                            );
                                                        })}
                                                    </ul>
                                                </td> : null}

                                            {secondaryPositions ?
                                                <td valign='top'>
                                                    <ul>
                                                        {secondaryPositions.map(pos => {
                                                            return (
                                                                <li>{pos.percentage}% of {pos.trade_symbol} <Link to={"/algo/" + pos.algoId}>{pos.display_name}</Link></li>
                                                            );
                                                        })}
                                                    </ul>
                                                </td> : null}
                                        </tr>

                                    </table>
                                    : null}
                            </div>
                        </center>

                    </div>
                    :
                    null
            }
        </div >);
}

export default DailyProfitChart;