import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import PositionsTable from './PositionTable.js';
import PositionsChart from './PositionsChart.js';

function Plan(props) {

    const [data, setData] = useState([]);
    const [previousPositions, setPreviousPositions] = useState([]);
    const [correlations, setCorrelations] = useState(null);

    useEffect(() => {
        (async function () {

            const loadCorrelations = async (positions) => {
                setCorrelations(null);
                if (positions && Array.isArray(positions) && positions.length) {
                    let symbols = "";
                    positions.forEach(p => {
                        symbols += p.netSymbol + ",";
                    })
                    if (!positions.find(p => p.netSymbol === "SPY")) {
                        symbols += "SPY";
                    }
                    const query = `/api/stockCorrelation?symbols=` + symbols +
                        "&days=20";
                    let matrix = await (await fetch(query)).json();
                    console.log("matrix", matrix);
                    setCorrelations(matrix);
                }
            }

            fetch(`/api/positions?planId=` + props.planId)
                .then(response => response.json())
                .then(text => {

                    setData(text)
                    loadCorrelations(text);
                });

            fetch(`/api/previousPositions?planId=` + props.planId)
                .then(response => response.json())
                .then(text => {

                    setPreviousPositions(text)
                });
        })();
    }, [props.planId]);

    async function reloadQuotes() {
        const pos = await (await fetch(`/api/positions?planId=` + props.planId)).json();
        setData(pos);

        const prev = await (await fetch(`/api/previousPositions?planId=` + props.planId)).json();
        setPreviousPositions(prev);
    }

    function handleRefreshClicked() {
        reloadQuotes();
    }

    return (
        <>
            <div>

                <PositionsTable previousPositions={previousPositions} planId={props.planId} accountValue={props.accountValue} positions={data} mobileView={props.mobileView} correlations={correlations} />

                <center>
                    <Link className="bg-transparent" onClick={handleRefreshClicked}>Refresh</Link>
                </center>

                <PositionsChart planId={props.planId} positions={data} />
                <br></br>
            </div>
        </>
    );
}

export default Plan;