import React, { useState, useEffect } from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from 'react-router-dom'

function EditTrade(props) {
    const [modalOpen, setModalOpen] = useState(false);

    const [symbol, setSymbol] = useState(null);
    const [purchaseAmount, setPurchaseAmount] = useState(null);
    const [sellAmount, setSellAmount] = useState(null);
    const [planTradeId, setPlanTradeId] = useState(null);
    const [isClosed, setIsClosed] = useState(false);
    const [trade, setTrade] = useState(null);
    const [brokerageConnections, setBrokerageConnections] = useState([]);
    const [optionQuote, setOptionQuote] = useState(null);

    useEffect(() => {
        (async function () {
            if (props.isOpen)
                setModalOpen(true);

            async function loadBrokerageConnections(userId) {
                console.log("Checking for active brokerage connections");
                let connections = await ((await fetch(`/api/brokeragesActive?userId=` + userId)).json());
                console.log("brokerage connections", connections);
                setBrokerageConnections(connections);
            }

            if (props.userTradeId) {
                console.log("Looking up " + props.userTradeId);
                let text = await (await fetch(`/api/userTradeDetails?userTradeId=` + props.userTradeId)).json();
                console.log("trade", text);
                setTrade(text);
                if (text.idplan_trades) {
                    setSymbol(text.option_symbol);
                    setPurchaseAmount(text.purchase_price);
                    setSellAmount(text.sell_price);
                    setPlanTradeId(text.idplan_trades)
                    setIsClosed(text.override_sell_date);
                    loadBrokerageConnections(text.userId);
                }
            }
        })();
    }, [props.userTradeId, props.isOpen]);


    const handleCloseModal = () => {
        setModalOpen(false);
        setOptionQuote(null);
        if (props.onClose)
            props.onClose();
    }

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };


    async function saveOptionTrade() {
        let url = `/api/userTrade?isOption=1&accountId=` + trade.accountId
        url += `&userId=` + trade.userId
        url += "&planTradeId=" + planTradeId
        url += "&option=" + symbol
        url += "&purchasePrice=" + purchaseAmount;
        url += "&sellPrice=" + sellAmount
        url += "&shares=" + trade.quantity
        if (isClosed)
            url += "&sellDate=" + new Date().toLocaleDateString()

        let result = await (await fetch(url, { method: 'POST' })).json();
        console.log("result", result);

        handleCloseModal();
    }

    async function saveTrade() {
        let url = `/api/userTrade?isOption=0&accountId=` + trade.accountId
        url += `&userId=` + trade.userId
        url += "&option=&planTradeId=" + planTradeId
        url += "&shares=" + purchaseAmount;

        let result = await (await fetch(url, { method: 'POST' })).json();
        console.log("result", result);

        handleCloseModal();
    }

    async function updateOptionQuote() {
        let url = `/api/optionQuote?userId=` + trade.userId + "&brokerage=schwab&symbol=" + symbol;
        let result = await (await fetch(url)).json();
        if (result && result.lastPrice) {
            setSellAmount(result.lastPrice);
            console.log("option quote", result);
            setOptionQuote(result);
        }
        console.log("option quote", result);
    }

    return (
        <>
            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={modalOpen}
                onClose={handleCloseModal}
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit Trade
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <center>
                            <b>{trade ? trade.trade_symbol : null} {trade ? trade.display_name : null}</b>
                        </center>
                        <br></br>
                        {trade ?
                            trade.is_option ?
                                <>
                                    Option: {symbol}
                                    <br></br>
                                    Contracts: {trade ? trade.quantity : null}
                                    <br></br>
                                    Purchase Price: $<input type="text" autoCorrect="off" value={purchaseAmount} onChange={(e) => setPurchaseAmount(e.target.value)} />
                                    <br></br>
                                    Last Price: $<input type="text" autoCorrect="off" value={sellAmount} onChange={(e) => setSellAmount(e.target.value)} /> {brokerageConnections.length ? <Link onClick={() => updateOptionQuote()}>🔄 🟢</Link> : null}
                                    <br></br>
                                    <input type="checkbox" checked={isClosed} onChange={(e) => setIsClosed(!isClosed)} /> Closed
                                    <br></br>
                                    <br></br>
                                    Underlying: {optionQuote ? optionQuote.underlyingPrice.toFixed(2) : "N/A"}
                                    <br></br>
                                    Delta: {optionQuote ? optionQuote.delta.toFixed(2) : "N/A"}
                                    <br></br>
                                    Theta: {optionQuote ? optionQuote.theta.toFixed(2) : "N/A"}
                                    <br></br>
                                    <br></br>
                                    <button class="btn btn-primary" onClick={saveOptionTrade} type="button">Update Option Trade</button>
                                    <br></br>
                                </>
                                :
                                <>
                                    Purchase Shares: <input type="text" autoCorrect="off" value={purchaseAmount} onChange={(e) => setPurchaseAmount(e.target.value)} />
                                    <br></br>
                                    <button class="btn btn-primary" onClick={saveTrade} type="button">Update Stock Trade</button>                        <br></br>
                                    <br></br>
                                </>
                            :
                            null
                        }
                        <div style={{ textAlign: 'right' }}>
                            <button class="btn btn-danger" onClick={handleCloseModal} type="button">Cancel</button>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}

export default EditTrade;