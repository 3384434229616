import React, { useState, useEffect } from 'react';
import RichTable from '../components/richTable/RichTable.jsx'
import TableHelpers from '../helpers/TableHelpers.js';
import Dropdown from 'react-bootstrap/Dropdown';

function PlanCorrelations(props) {

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filterSymbol, setFilterSymbol] = useState(null);
    const [filterTradeSymbol, setFilterTradeSymbol] = useState(null);
    const [filterAlgoType, setFilterAlgoType] = useState(null);

    useEffect(() => {
        (async function () {
            if (props.planId) {
                console.log("Getting correlation data", props.planId)
                const query = `/api/planAlgoCorrelations?planId=` + props.planId
                let correlations = await (await fetch(query)).json();
                console.log("data", correlations);
                if (correlations && correlations.length) {
                    setFilterSymbol(correlations[0].primary_symbol);
                    setFilterAlgoType(correlations[0].primary_algo_type);
                }
                setData(correlations);
            }
        })();
    }, [props.planId]);

    useEffect(() => {
        (async function () {
            let filteredData = filterSymbol ? data.filter(row => row.primary_symbol === filterSymbol && row.secondary_symbol === filterSymbol) : data
            filteredData = filterTradeSymbol ? filteredData.filter(row => row.primary_trade_symbol === filterTradeSymbol && row.secondary_trade_symbol === filterTradeSymbol) : filteredData
            filteredData = filterAlgoType ? filteredData.filter(row => row.primary_algo_type === filterAlgoType && row.secondary_algo_type === filterAlgoType) : filteredData
            setFilteredData(filteredData);
        })();
    }, [data, filterSymbol, filterTradeSymbol, filterAlgoType]);

    function getCorrelationColumns() {
        let results = [];
        if (filteredData && filteredData.length) {
            const algoArray = [...new Set(filteredData.map(row => row.primary_algoId))];
            results.push({ title: "Algo", field: 'primaryDisplayName' })
            algoArray.forEach(algo => {
                let displayName = filteredData.find(row => row.primary_algoId === algo).primary_display_name
                results.push({ title: displayName, field: algo, formatter: TableHelpers.percentFormatterNoDecimals })
            });
        }
        return results;
    }

    function getCorrelationData() {

        let results = [];
        if (filteredData && filteredData.length) {
            const algoArray = [...new Set(filteredData.map(row => row.primary_algoId))];
            algoArray.forEach(algo => {
                let correlations = filteredData.filter(row => row.primary_algoId === algo)
                let displayName = filteredData.find(row => row.primary_algoId === algo).primary_display_name
                let row = {
                    algoId: algo,
                    primaryDisplayName: displayName
                };
                correlations.forEach(correlation => {
                    row[correlation.secondary_algoId] = correlation.percent;
                })
                results.push(row);
            })

        }
        return results;
    }

    function getUniqueSymbols() {
        if (data)
            return [...new Set(data.map(row => row.primary_symbol))].sort();
        else
            return [];
    }

    function getUniqueTradeSymbols() {
        if (data)
            return [...new Set(data.map(row => row.primary_trade_symbol))].sort();
        else
            return [];
    }

    return (
        <div>
            <div className="sameline">
                Filter by Symbol:&nbsp;&nbsp;
                <Dropdown onSelect={(e) => setFilterSymbol(e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {filterSymbol}
                    </Dropdown.Toggle>
                    <Dropdown.Menu  >
                        <Dropdown.Item as='group' eventKey={null} value={null}>None</Dropdown.Item>
                        {getUniqueSymbols().map(sym => {
                            return (
                                <Dropdown.Item as='sym' eventKey={sym} value={sym}>{sym}</Dropdown.Item>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>

                Filter by Trade Symbol:&nbsp;&nbsp;
                <Dropdown onSelect={(e) => setFilterTradeSymbol(e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {filterTradeSymbol}
                    </Dropdown.Toggle>
                    <Dropdown.Menu  >
                        <Dropdown.Item as='group' eventKey={null} value={null}>None</Dropdown.Item>
                        {getUniqueTradeSymbols().map(sym => {
                            return (
                                <Dropdown.Item as='sym' eventKey={sym} value={sym}>{sym}</Dropdown.Item>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>

                Filter by Algo Type:&nbsp;&nbsp;
                <Dropdown onSelect={(e) => setFilterAlgoType(e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {filterAlgoType}
                    </Dropdown.Toggle>
                    <Dropdown.Menu  >
                        <Dropdown.Item as='group' eventKey={null} value={null}>None</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={"Trend"} value={"Trend"}>Trend</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={"Mean Reversion"} value={"Mean Reversion"}>Mean Reversion</Dropdown.Item>
                        <Dropdown.Item as='group' eventKey={"Candlestick"} value={"Candlestick"}>Candlestick</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <br></br>

            <div class="smallest-text">
                <RichTable data={getCorrelationData()} className="table-striped table-hover table-condensed fixed" mappers={getCorrelationColumns()} />
            </div>
        </div >

    );
}

export default PlanCorrelations;