import Algos from './Algos.js';
import React, { useState, useEffect } from 'react';
import AlgosChart from '../algos/AlgosChart.js';
import ActionResult from '../components/ActionResult.js';

function ManageAlgos(props) {

    const [itemsChanged, setItemsChanged] = useState(0);
    const [planMetadata, setPlanMetadata] = useState({});
    const [enableAlgoUpdates, setEnableAlgoUpdates] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        (async function () {
            let metadata = await (await fetch(`/api/planMetaData?planId=` + props.planId)).json();
            if (metadata && metadata.length)
                setPlanMetadata(metadata[0])
        })();
    }, [props.planId]);


    const onAddAlgoToPlan = async (algoId) => {
        console.log("onAddAlgoToPlan", algoId);

        const text = await (await fetch(`/api/addAlgoToPlan?planId=` + props.planId + `&algoId=` + algoId)).json();
        setResult(text);
        setItemsChanged(itemsChanged + 1);
        console.log(text);
    }

    const onAllocationUpdated = async (algoId) => {
        setItemsChanged(itemsChanged + 1);
    }

    function shouldAllowEdits() {
        if (props.role === 3) return true;
        if (props.userId === planMetadata.userIdOwner) return true;
        return false;
    }
    function shouldAllowAlgoUpdates() {
        return (props.role === 3) && enableAlgoUpdates;
    }

    return (
        <>
            <div>
                <center>
                    <AlgosChart planId={props.planId} itemsChanged={itemsChanged} />
                </center>

                <center>
                    <input type='checkbox' checked={enableAlgoUpdates} onClick={() => setEnableAlgoUpdates(!enableAlgoUpdates)} />Enable Edits
                </center>

                <Algos mobileView={props.mobileView} showStats={true} allowEdit={shouldAllowEdits} allowAlgoUpdates={shouldAllowAlgoUpdates} planId={props.planId} onAllocationUpdated={onAllocationUpdated} onAddAlgoToPlan={onAddAlgoToPlan} itemsChanged={itemsChanged} />

            </div>
            <ActionResult result={result} />
        </>
    );
}

export default ManageAlgos;