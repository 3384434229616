import React from 'react';
import tableHelpers from '../helpers/TableHelpers.js'
import backtestResultsHelpers from '../helpers/BacktestResultsHelpers.js'
import RichTable from '../components/richTable/RichTable.jsx'

function RecentReturns(props) {

    function getReturns() {

        if (!props.backtestResults || !props.backtestResults.returns)
            return [];

        let result = JSON.parse(JSON.stringify(props.backtestResults.returns));

        if (props.accountStartDate) {
            result.push({
                name: 'Account Start Date',
                result: backtestResultsHelpers.getReturnSince(props.backtestResults, new Date(props.accountStartDate), false),
                spyResult: backtestResultsHelpers.getReturnSince(props.backtestResults, new Date(props.accountStartDate), true),
            });
        }

        result.forEach(r => {
            let startAmount = props.accountValue / (r.result / 100 + 1);
            let profit = props.accountValue - startAmount;
            let spyProfit = (startAmount * (1 + r.spyResult / 100) - startAmount);
            r.dollarDiff = profit - spyProfit;
            r.dollarProfit = profit;
            r.differencePercent = r.result - r.spyResult;
        })

        return result;
    }

    function resultFormatter(cell, row) {
        if (row.name === "Current Drawdown" && row.result <= 0) {
            return tableHelpers.percentFormatter(cell, row) + " 🚨"
        } else {
            return tableHelpers.percentFormatter(cell, row)
        }
    }

    const returnMapper = [
        { title: 'Category', field: 'name' },
        { title: 'S&P 500', field: 'spyResult', formatter: tableHelpers.percentFormatter },
        { title: 'Plan', field: 'result', formatter: resultFormatter },
        { title: 'Difference', field: 'differencePercent', formatter: tableHelpers.percentChangeFormatterOneDecimal },
        { title: 'P/L $', field: 'dollarProfit', formatter: tableHelpers.moneyChangeFormatterNoCents, hidden: props.mobileView },
    ]

    getReturns();

    return (
        <>
            <div>
                <RichTable data={getReturns()} mappers={returnMapper} className="table-striped table-hover table-condensed" />
            </div>
        </>
    );
}

export default RecentReturns; 