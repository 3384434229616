module.exports = {

    percentFormatter: function (cell, row) {
        if (cell === null) return "";
        else if (isNaN(parseFloat(cell))) return "";
        else return `${parseFloat(cell).toFixed(2)}%`;
    },

    percentFormatterOneDecimal: function (cell, row) {
        if (cell === null) return "";
        else if (isNaN(parseFloat(cell))) return "";
        else return `${parseFloat(cell).toFixed(1)}%`;
    },

    percentChangeFormatter: function (cell, row) {
        if (cell === null) return "";
        else if (isNaN(parseFloat(cell))) return "";
        else return (parseFloat(cell) > 0 ? `+` : ``) + `${parseFloat(cell).toFixed(2)}%`;
    },

    percentChangeFormatterOneDecimal: function (cell, row) {
        if (cell === null) return "";
        else if (isNaN(parseFloat(cell))) return "";
        else return (parseFloat(cell) > 0 ? `+` : ``) + `${parseFloat(cell).toFixed(1)}%`;
    },

    boolFormatter: function (cell, row) {
        if (cell === null) return "";
        else if (cell === true || cell === 1) return "✅";
        else return "❌"
    },

    percentFormatterNoDecimals: function (cell, row) {
        if (cell === null) return "";
        else if (isNaN(parseFloat(cell))) return "";
        else return `${parseFloat(cell).toFixed(0)}%`;
    },

    condensedPercentFormatter: function (cell, row) {
        if (cell === null) return "";
        else if (isNaN(parseFloat(cell))) return "";
        else {
            let value = parseFloat(cell);
            if (value > -10 && value < 10) return `${value.toFixed(1)}%`;
            else return `${value.toFixed(0)}%`;
        }
    },

    moneyFormatter: function (cell, row) {
        if (cell === null) return "";
        else if (isNaN(parseFloat(cell))) return "";

        let dollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return dollar.format(cell);
    },

    moneyFormatterNoCents: function (cell, row) {
        if (cell === null) return "";
        else if (isNaN(parseFloat(cell))) return "";

        let dollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
        });
        return dollar.format(cell);
    },

    moneyChangeFormatterNoCents: function (cell, row) {
        if (cell === null) return "";
        else if (isNaN(parseFloat(cell))) return "";

        let dollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
        });
        let sign = cell > 0 ? "+" : "";
        return sign + dollar.format(cell);
    },

    intFormatter: function (cell, row) {
        if (cell === null) return "";
        else if (isNaN(parseInt(cell))) return "";
        else return `${parseInt(cell)}`;
    },

    decimalFormatter: function (cell, row) {
        if (cell === null) return "";
        else if (isNaN(parseFloat(cell))) return "";
        else return `${parseFloat(cell).toFixed(2)}`;
    },

    jsonFormatter: function (cell, row) {
        if (cell === null) return "";
        else return JSON.stringify(cell);
    },

    dateFormmatter: function (cell, row) {
        if (cell === null || cell === undefined) return "";
        else if (isNaN(Date.parse(cell))) return "";
        else return `${new Date(cell).toLocaleDateString('en-US', { timeZone: 'UTC' })}`;
    },

    dateESTFormmatter: function (cell, row) {
        if (cell === null || cell === undefined) return "";
        else if (isNaN(Date.parse(cell))) return "";
        else return `${new Date(cell).toLocaleDateString('en-US', { timeZone: 'EST' })}`;
    },

    datetimeESTFormmatter: function (cell, row) {
        if (cell === null || cell === undefined) return "";
        else if (isNaN(Date.parse(cell))) return "";
        else return `${new Date(cell).toLocaleString('en-US', { timeZone: 'EST' })}`;
    },

    dynamicFormatter: function (cell, row) {
        if (row.format === 'int')
            return module.exports.intFormatter(cell, row);
        else if (row.format === 'percent')
            return module.exports.percentFormatter(cell, row);
        else if (row.format === 'currency')
            return module.exports.moneyFormatter(cell, row);
        else if (row.format === 'date')
            return module.exports.dateFormmatter(cell, row);
        if (row.format === 'float')
            return module.exports.decimalFormatter(cell, row);
        else return cell;
    },

    dynamicFormatterWithFormat: function (cell, row, format) {
        if (format === 'int')
            return module.exports.intFormatter(cell, row);
        else if (format === 'percent')
            return module.exports.percentFormatter(cell, row);
        else if (format === 'currency')
            return module.exports.moneyFormatter(cell, row);
        else if (format === 'date')
            return module.exports.dateFormmatter(cell, row);
        if (format === 'float')
            return module.exports.decimalFormatter(cell, row);
        else return cell;
    },

    numberSort: function (a, b, order, sortField) {   // order is desc or asc
        if (order === 'desc') {
            return a[sortField] - b[sortField];
        } else {
            return b[sortField] - a[sortField];
        }
    },

    dateSort: function (a, b, order, sortField) {   // order is desc or asc
        if (order === 'desc') {
            return new Date(a[sortField]) - new Date(b[sortField]);
        } else {
            return new Date(b[sortField]) - new Date(a[sortField]);
        }
    },

    getMaxChartValue: function (chartData, index) {
        let max = 0;
        chartData.forEach(d => {
            if (d[index] > max) max = d[index];
        })
        return max;
    },

    getMinChartValue: function (chartData, index) {
        let min = 0;
        chartData.forEach(d => {
            if (d[index] < min) min = d[index];
        })
        return min;
    }
}
