const localStorageDefaults = {
  userId: -1,
  role: 0,
  activePlan: -1,
  activePlanName: 'No plans available',
  accountValue: 0,
  activeAccountId: -1,
  activeAccountName: 'Select Account',
  activeAccountStartDate: null,
  agreedToTerms: 'false'
}

export function clearLocalStorage() {
  console.log('clearing local storage')
  for (let key in localStorageDefaults) {
    localStorage.removeItem(key)
  }
}

export function getDefault(key) {
  return localStorageDefaults[key]
}

export function getValueOrDefault(key) {
  return localStorage.getItem(key) || localStorageDefaults[key]
}