import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import Dropdown from 'react-bootstrap/Dropdown';

function AlertTradeAnalysis(props) {

    const [trades, setTrades] = useState([])
    const [field, setField] = useState("")
    const [fields, setFields] = useState([])
    const [filter, setFilter] = useState("all")
    const [action, setAction] = useState("entry")

    useEffect(() => {
        (async function () {
            if (props.trades && props.trades.length) {
                setTrades(props.trades);

                if (props.trades[0].purchaseData) {
                    let purchase = Object.keys(props.trades[0].purchaseData)
                    let sell = Object.keys(props.trades[0].sellData)
                    const combinedArray = [...purchase, ...sell];
                    setFields([...new Set(combinedArray)]);
                }
            } else {
                setTrades([]);
                setFields([])
                setField("");
            }
        })();
    }, [props.trades]);

    function getChartData() {
        if (trades && field !== "") {
            let ret = [];
            ret.push(["Trade", field, "Avg"])
            let counter = 0;
            let sum = 0;
            trades.forEach(t => {
                if ((filter === "all" || filter === "winners") && t.profitPercentage > 0) {
                    if (action === "entry" && t.purchaseData) {
                        ret.push([++counter, t.purchaseData[field]])
                        sum += t.purchaseData[field]
                    } else if (action === "exit" && t.sellData) {
                        ret.push([++counter, t.sellData[field]])
                        sum += t.sellData[field]
                    }
                } else if ((filter === "all" || filter === "losers") && t.profitPercentage < 0)
                    if (action === "entry" && t.purchaseData) {
                        ret.push([++counter, t.purchaseData[field]])
                        sum += t.purchaseData[field]
                    } else if (action === "exit" && t.sellData) {
                        ret.push([++counter, t.sellData[field]])
                        sum += t.sellData[field]
                    }
            })
            for (let i = 1; i < ret.length; i++) {
                ret[i][2] = sum / counter;
            }
            return ret;
        } else {
            return [];
        }
    }

    let chartData = getChartData();
    return (
        <div>
            {
                trades && trades.length > 0 ?
                    <>
                        <center>
                            <div className="sameline">
                                <Dropdown width="150" onSelect={(e) => setFilter(e)}>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                        {filter}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu  >
                                        <Dropdown.Item as='all' eventKey={'all'} value={'all'}>All</Dropdown.Item>
                                        <Dropdown.Item as='winners' eventKey={'winners'} value={'winners'}>winners</Dropdown.Item>
                                        <Dropdown.Item as='losers' eventKey={'losers'} value={'losers'}>losers</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown width="150" onSelect={(e) => setAction(e)}>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                        {action}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu  >
                                        <Dropdown.Item as='entry' eventKey={'entry'} value={'entry'}>Entry</Dropdown.Item>
                                        <Dropdown.Item as='exit' eventKey={'exit'} value={'exit'}>Exit</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown width="150" onSelect={(e) => setField(e)}>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                        {field}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu  >
                                        {fields.sort((l, r) => l.name < r.name ? -1 : 1).map(f => {
                                            return (<Dropdown.Item as='field' eventKey={f} value={f}>{f}</Dropdown.Item>)
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </center>

                        {chartData && chartData.length ?
                            <Chart
                                chartType="ComboChart"
                                width={"100%"}
                                data={chartData}
                                chartPackages={["corechart", "controls"]}
                                options={{
                                    seriesType: "bars",
                                    series: { 1: { type: "line" } },
                                }}
                            />
                            : null}
                    </>
                    :
                    null
            }
        </div >);
}

export default AlertTradeAnalysis;