import React, { useState } from 'react';
import Replay from './Replay';
import StressTest from './StressTest'
import AlgoCorrelation from '../algos/AlgoCorrelation'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AlgoRobustness from '../algos/AlgoRobustness';
import LiveVsBacktest from "./LiveVsBacktest.js"
import PlanMetrics from './PlanMetrics.js';
import { useParams } from 'react-router-dom';
import PlanCorrelations from './PlanCorrelations.js';
import CircuitBreakerAnalysis from './CircuitBreakerAnalysis.js';

function PlanAnalysis(props) {

    let { activeTab } = useParams();

    const [tab, setTab] = useState(activeTab ? activeTab : 'metrics');

    function shouldAllow() {
        return (props.role >= 2);
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };


    return (
        <div>
            {shouldAllow() ?
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tab}
                            onChange={handleChangeTab}
                            textColor="secondary"
                            indicatorColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab value="metrics" label="Summary" />
                            <Tab value="robustness" label="Robustness" />
                            <Tab value="livevsbacktest" label="Live vs Backtest" />
                            <Tab value="circuitbreaker" label="Circuit Breaker" />
                            <Tab value="montecarlo" label="Monte Carlo" />
                            <Tab value="plancorrelations" label="Correlation Grid" />
                            <Tab value="correlations" label="Algo Comparison" />
                            <Tab value="replay" label="Replay" />
                        </Tabs>
                    </Box>
                    <br></br>

                    {tab === "metrics" ?
                        <>
                            <PlanMetrics planId={props.planId} userId={props.userId} role={props.role} />
                        </>
                        : null}
                    {tab === "circuitbreaker" ?
                        <>
                            <CircuitBreakerAnalysis planId={props.planId} userId={props.userId} role={props.role} />
                        </>
                        : null}
                    {tab === "replay" ?
                        <>
                            <Replay planId={props.planId} />
                        </>
                        : null}
                    {tab === "montecarlo" ?
                        <>
                            <StressTest planId={props.planId} onAlgoSelected={props.onAlgoSelected} />
                        </>
                        : null}
                    {tab === "correlations" ?
                        <>
                            <AlgoCorrelation planId={props.planId} onAlgoSelected={props.onAlgoSelected} />
                        </>
                        : null}

                    {tab === "robustness" ?
                        <>
                            <AlgoRobustness mobileView={props.mobileView} planId={props.planId} />
                        </>
                        : null}
                    {tab === "livevsbacktest" ?
                        <>
                            <LiveVsBacktest planId={props.planId} />
                        </>
                        : null}
                    {tab === "plancorrelations" ?
                        <>
                            <PlanCorrelations planId={props.planId} />
                        </>
                        : null}


                </>
                :
                <div class="empty-page" >Not allowed</div>
            }


        </div>
    );
}

export default PlanAnalysis;