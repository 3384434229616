import React, { useState, useEffect } from 'react';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dropdown from 'react-bootstrap/Dropdown';

function CreateAlgoModal(props) {
    const [createAlgoDisplayName, setCreateAlgoDisplayName] = useState("");
    const [createAlgoTradeSymbol, setCreateAlgoTradeSymbol] = useState(props.symbol);
    const [createAlgoType, setCreateAlgoType] = useState("Mean Reversion");
    const [createAlgoIsShort, setCreateAlgoIsShort] = useState(false);

    useEffect(() => {
        (async function () {
            setCreateAlgoTradeSymbol(props.symbol);
        })();
    }, [props.symbol]);

    async function handleCreateAlgoSubmit() {
        console.log("handleCreateAlgoSubmit");
        const url = "/api/algoCreate?name=" + props.name + "&displayName=" + createAlgoDisplayName + "&baseSymbol=" + props.symbol + "&tradeSymbol=" + createAlgoTradeSymbol + "&short=" + (createAlgoIsShort ? "1" : "0") + "&pyramids=1&type=" + createAlgoType;
        console.log(url);
        const r = await (await fetch(url)).json();
        console.log(r);
        if (r.success && r.id) {
            const syncUrl = `/api/algoSyncWithStrategyAlerts?algoId=` + r.id;
            await (await fetch(syncUrl, {
                method: 'POST'
            })).json();

            const robustnessTestsUrl = `/api/algoRobustnessTests?algoId=` + r.id;
            await (await (fetch(robustnessTestsUrl, { method: 'POST' }))).json();
        }

        if (props.onClose)
            props.onClose(r);
    }

    function handleModalClosed() {
        if (props.onClose)
            props.onClose(null);
    }

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={props.isOpen}
                onClose={() => handleModalClosed()}
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Create Algo
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Identifier: {props.name}
                        <br></br>
                        <br></br>
                        Symbol: {props.symbol}
                        <br></br>
                        <br></br>
                        Display Name: <input type="text" autoCorrect="off" onChange={(e) => setCreateAlgoDisplayName(e.target.value)} />
                        <br></br>
                        <br></br>
                        Trade Symbol: <input type="text" autoCorrect="off" value={createAlgoTradeSymbol} onChange={(e) => setCreateAlgoTradeSymbol(e.target.value)} />
                        <br></br>
                        <br></br>
                        <div className="sameline">
                            Type:&nbsp;&nbsp;
                            <Dropdown width="150" onSelect={(e) => setCreateAlgoType(e)}>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                    {createAlgoType}
                                </Dropdown.Toggle>

                                <Dropdown.Menu  >
                                    <Dropdown.Item as='MeanReversion' eventKey={'Mean Reversion'} value={'Mean Reversion'}>Mean Reversion</Dropdown.Item>
                                    <Dropdown.Item as='Trend' eventKey={'Trend'} value={'Trend'}>Trend</Dropdown.Item>
                                    <Dropdown.Item as='Candlestick' eventKey={'Candlestick'} value={'Candlestick'}>Candlestick</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <br></br>
                        Is Short: <input type="checkbox" onChange={(e) => setCreateAlgoIsShort(!createAlgoIsShort)} />
                        <br></br>
                        <br></br>
                        <button class="btn btn-primary" onClick={handleCreateAlgoSubmit} type="button">Submit</button>
                        <br></br>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}

export default CreateAlgoModal;