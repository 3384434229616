import React, { useEffect } from 'react';
import tableHelpers from '../helpers/TableHelpers.js'
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable.jsx'
import { createDefaultSorter, SortOrder } from '../components/richTable/sorters.js'
import ExpectancyChart from '../components/ExpectancyChart.js'
import OHLCBarChart from '../components/OHLCBarChart.js'
import Tooltip from '../components/Tooltip.js'
import StockQuickLook from '../stock/StockQuickLook.js'

const CASH_SYMBOL = 'Cash Balance'

const symbolSorter = createDefaultSorter('netSymbol')
const symbolAndCashSorter = (a, b, direction) => {
    const keepCashAtBottom = direction === SortOrder.REVERSE ? -1 : 1

    if (a.netSymbol === CASH_SYMBOL) return 1 * keepCashAtBottom
    if (b.netSymbol === CASH_SYMBOL) return -1 * keepCashAtBottom
    return symbolSorter(a, b)
}

function PositionsTable(props) {

    useEffect(() => {
        (async function () {

        })();
    }, []);

    function getTotalChange() {
        var total = 0;
        if (props.previousPositions) {
            for (let i = 0; i < props.previousPositions.length; i++) {
                const pos = props.previousPositions[i];
                total += pos.position * pos.change_percent / 100;
            }
        }
        return total;
    }

    function getAggregateSpyCorrelation() {
        let total = 0;
        props.positions.forEach(p => {
            total += p.netExposure;
        })
        return total;
    }

    function getProfit(symbol, quote, avgPrice) {
        if (avgPrice > 0 && quote > 0) {
            return ((quote - avgPrice) / avgPrice * 100);
        } else {
            return 0;
        }
    }

    function shouldShowMergedPositionWarning() {
        let foundOne = false;
        props.positions.forEach(pos => {
            if (pos.isHedged)
                foundOne = true;
        });
        return foundOne;
    }

    function getPositions() {

        let results = [];
        let total = 0;
        let totalWithGainLoss = 0;
        props.positions.forEach(pos => {
            const quote = pos.lastClose;
            total += pos.netPosition;
            totalWithGainLoss += pos.netPositionIncludingGainLoss;

            results.push({
                netSymbol: pos.netSymbol,
                netPosition: pos.netPosition,
                precisePercentage: pos.precisePercentage,
                averagePrice: pos.avgPrice,
                quote: quote,
                change: pos.todayChangePercent,
                profit: getProfit(pos.netSymbol, quote, pos.avgPrice),
                dollarAmount: (pos.netPosition / 100 * props.accountValue),
                shares: quote > 0 && props.accountValue ? Math.max(1, (props.accountValue * pos.netPosition / 100 / quote)) : 0,
                netProfitImpact: getProfitImpact(pos),
                isHedged: pos.isHedged,
                todayOpen: pos.todayOpen,
                todayHigh: pos.todayHigh,
                todayLow: pos.todayLow,
                previousClose: pos.previousClose,
                netPositionIncludingGainLoss: pos.netPositionIncludingGainLoss
            });
        });

        results.push({
            netSymbol: CASH_SYMBOL,
            netPosition: 100 - total,
            averagePrice: 0,
            quote: 0,
            change: 0,
            profit: 0,
            dollarAmount: ((100 - total) / 100 * props.accountValue),
            shares: 0,
            netProfitImpact: 0,
            isHedged: false,
            todayOpen: 0,
            todayHigh: 0,
            todayLow: 0,
            previousClose: 0,
            netPositionIncludingGainLoss: 100 - totalWithGainLoss
        });

        return results;
    }

    function getProfitImpact(pos) {
        var quote = pos.lastClose;
        return getProfit(pos.netSymbol, quote, pos.avgPrice) *
            (pos.netPosition / 100);
    }

    function getNetProfit() {
        let total = 0.0;
        props.positions.forEach(pos => {
            const profit = getProfitImpact(pos);
            total += profit;
        })
        return total;
    }

    function getStats() {
        let indexQuote = null;
        if (props.indexQuotes && props.indexQuotes.length) {
            indexQuote = props.indexQuotes.find(q => q.symbol === "SPY")
        }
        return [{
            netChange: getTotalChange(),
            dollarChange: props.accountValue * getTotalChange() / 100,
            netProfit: getNetProfit(),
            indexHigh: indexQuote ? indexQuote.high : undefined,
            indexLow: indexQuote ? indexQuote.low : undefined,
            indexOpen: indexQuote ? indexQuote.open : undefined,
            indexLast: indexQuote ? indexQuote.last : undefined,
            indexPClose: indexQuote ? indexQuote.pclose : undefined,
            indexPercentChange: indexQuote ? indexQuote.percentChange : undefined,
            overallRisk: getAggregateSpyCorrelation()
        }]
    }

    function profitChartFormatter(cell, row) {

        let min = undefined;
        let max = undefined;
        props.positions.forEach(pos => {
            let profit = (pos.currentPrice - pos.avgPrice) / pos.avgPrice * 100;
            if (min === undefined || profit < min) min = profit;
            if (max === undefined || profit > max) max = profit;
        });

        return (
            <ExpectancyChart hideLabels={true} profit={cell} avgLoss={min} avgWin={max}></ExpectancyChart>
        )
    }

    function todaysChangeChartFormatter(cell, row) {
        let highChange = (row.todayHigh - row.previousClose) / row.previousClose * 100;
        let lowChange = (row.todayLow - row.previousClose) / row.previousClose * 100;
        let openChange = (row.todayOpen - row.previousClose) / row.previousClose * 100;
        return <div className="sameline">
            <OHLCBarChart width="80px" hideLabels={true} high={highChange} low={lowChange} open={openChange} close={row.change} />
        </div>
    }

    function todaysIndexChangeChartFormatter(cell, row) {
        console.log(row);
        let highChange = (row.indexHigh - row.indexPClose) / row.indexPClose * 100;
        let lowChange = (row.indexLow - row.indexPClose) / row.indexPClose * 100;
        let openChange = (row.indexOpen - row.indexPClose) / row.indexPClose * 100;
        let change = row.indexPercentChange;
        return <div className="sameline">
            <OHLCBarChart width="80px" hideLabels={true} high={highChange} low={lowChange} open={openChange} close={change} />
        </div>
    }

    function symbolFormatter(cell, row) {
        if (row.isHedged)
            return (
                <><Link id="link" className="bg-transparent" to={`/stock/${cell}`}>{cell}</Link>

                    &nbsp;&nbsp;<Tooltip textClass={"tooltip-link"} text={"🔍"} width={360} borderWidth={"1px"} position="right center" >
                        <StockQuickLook symbol={cell} planId={props.planId} />
                    </Tooltip >

                    <br></br><br></br>(*see below)
                </>

            )
        else if (row.netSymbol === CASH_SYMBOL)
            return (
                <>{cell}</>
            )
        else
            return (
                <>
                    <>
                        <Link id="link" className="bg-transparent" to={`/stock/${cell}`}>{cell}</Link></>
                    &nbsp;&nbsp;<Tooltip textClass={"tooltip-link"} text={"🔍"} width={360} borderWidth={"1px"} position="right center" >
                        <StockQuickLook symbol={cell} planId={props.planId} />
                    </Tooltip >
                </>
            )
    }

    function getReturnsRowClassName(row) {
        return "analytics_hidden";
    }

    const returnMapper = [
        { title: `Today`, field: 'netChange', formatter: tableHelpers.percentChangeFormatter },
        { title: `S&P 500`, field: 'indexChange', formatter: todaysIndexChangeChartFormatter },
        { title: `S&P500 Exposure`, field: 'overallRisk', formatter: tableHelpers.percentFormatterNoDecimals },
        { title: `Today $ Change`, field: 'dollarChange', formatter: tableHelpers.moneyFormatterNoCents },
    ]

    const positionsMapper = [
        { title: `Symbol`, field: 'netSymbol', formatter: symbolFormatter, sorters: symbolAndCashSorter, isDefaultSort: true },
        { title: `Amount`, field: 'netPosition', formatter: tableHelpers.percentFormatterNoDecimals, sorters: true },
        { title: `Avg Price`, field: 'averagePrice', hidden: props.mobileView, formatter: tableHelpers.moneyFormatter, sorters: true },
        { title: `Current Price`, field: 'quote', hidden: props.mobileView, formatter: tableHelpers.moneyFormatter, sorters: true },
        { title: `P/L`, field: 'profit', formatter: profitChartFormatter, sorters: true },
        { title: `Today's Change`, field: 'change', formatter: props.mobileView ? tableHelpers.percentChangeFormatter : todaysChangeChartFormatter, sorters: true },
        { title: `Realized Amount`, field: 'netPositionIncludingGainLoss', formatter: tableHelpers.percentFormatterOneDecimal, sorters: true, hidden: props.mobileView },
        { title: `Net P/L`, field: 'netProfitImpact', hidden: props.mobileView, formatter: tableHelpers.percentChangeFormatter, sorters: true },
        { title: `$ Amount`, field: 'dollarAmount', hidden: props.mobileView, formatter: tableHelpers.moneyFormatter, sorters: true },
        { title: `Shares`, field: 'shares', hidden: props.mobileView, formatter: tableHelpers.intFormatter, sorters: true },
    ]

    return (
        <div>
            <RichTable data={getStats()} rowClassName={getReturnsRowClassName} mappers={returnMapper} className="table-striped table-hover table-condensed" />
            <br></br>

            <RichTable data={getPositions()} mappers={positionsMapper} className="table-striped table-hover table-condensed" />
            {
                shouldShowMergedPositionWarning() ?
                    <div>
                        * This is a result of hedging a long position with a short trade.<br></br>
                        Go to <Link id="link" className="bg-transparent" to={"/activeTrades"}>Active Trades</Link> to see full details
                    </div>
                    :
                    null
            }
        </div >);
}

export default PositionsTable;