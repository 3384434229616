import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CandleStickChart from '../stock/CandlestickChart';
import { Link } from 'react-router-dom'
import AlgoAverageTrade from '../algos/AlgoAverageTrade.js';
import Tooltip from '../components/Tooltip.js'

function TradeDetails(props) {

    let { planTradeId } = useParams();

    const [tradeDetails, setTradeDetails] = useState(null);

    useEffect(() => {
        (async function () {

            const url = `/api/tradeDetails?planTradeId=` + planTradeId;
            const text = await (await fetch(url)).json();
            console.log(text);
            if (text.success) {
                setTradeDetails(text.trade);
            }
        })();
    }, [planTradeId]);

    let trades = [];
    if (tradeDetails) {
        trades.push({
            purchase_date: tradeDetails.purchase_date,
            sell_date: tradeDetails.sell_date,
            purchase_price: tradeDetails.purchase_price,
            sell_price: tradeDetails.sell_price,
            allocation: tradeDetails.allocation
        })
    }

    return (
        <>
            {tradeDetails ?
                <>
                    Algo: <Link to={"/algo/" + tradeDetails.algoId}>{tradeDetails.display_name}</Link>
                    <br></br>
                    Symbol: <Link to={"/stock/" + tradeDetails.trade_symbol}>{tradeDetails.trade_symbol}</Link>
                    <br></br>
                    Entry: {new Date(tradeDetails.purchase_date).toLocaleDateString()} at {tradeDetails.purchase_price}
                    <Tooltip text={" 🔍"} json={tradeDetails.purchase_data} position={"right top"} />
                    <br></br>
                    Exit: {tradeDetails.sell_date ? new Date(tradeDetails.sell_date).toLocaleDateString() + " at " + tradeDetails.sell_price : ""}
                    {tradeDetails.sell_data ? <Tooltip text={" 🔍"} json={tradeDetails.sell_data} position={"right top"} /> : null}
                    <br></br>
                    <br></br>
                    <CandleStickChart showAveragePrice={true} symbol={tradeDetails.trade_symbol} trades={trades} width={window.innerWidth * .5} height={window.innerHeight * .5} />

                    <h5>Average Trade Timeline</h5>
                    <AlgoAverageTrade algoId={tradeDetails.algoId} hideActiveTrade={false} planTradeId={planTradeId} />
                </>
                : null}
        </>
    );
}

export default TradeDetails;