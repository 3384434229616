import React, { useState, useEffect, useRef } from 'react';
import TableHelpers from '../helpers/TableHelpers';
import { DateTime, Settings } from "luxon";
import Tooltip from '../components/Tooltip';
import RichTable from '../components/richTable/RichTable'
import { createDefaultSorter } from '../components/richTable/sorters'
import { Link } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress';
import AutomateTrades from './AutomateTrades.js'
import AutomateTrade from './AutomateTrade.js'
import OptionChain from './OptionChain.js';
import SneakPeek from './SneakPeek.js';

function TradeInstructions(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [tradeDate, setTradeDate] = useState(null);
    const [trades, setTrades] = useState([]);
    const [trade, setTrade] = useState(null);
    const [optionTrades, setOptionTrades] = useState(null)
    const [closedTrades, setClosedTrades] = useState({})
    const [remainingTime, setRemainingTime] = useState(null)
    const [marketHours, setMarketHours] = useState(null);
    const [isAutomatedTrades, setIsAutomatedTrades] = useState(false);
    const [isPlacingOrder, setIsPlacingOrder] = useState(false);
    const timerRef = useRef(null);
    const [brokerageConnections, setBrokerageConnections] = useState([]);
    const [planInfo, setPlanInfo] = useState(null);

    useEffect(() => {
        (async function () {

            const loadOptionTrades = async (allocationChanges) => {
                let result = [];
                for (let i = 0; i < allocationChanges.length; i++) {
                    let change = allocationChanges[i];
                    console.log(change);
                    if (change.action === "buy") {
                        result.push({
                            action: change.action,
                            symbol: change.user_option_symbol ? change.user_option_symbol : "",
                            savedSymbol: change.user_option_symbol,
                            underlyingSymbol: change.base_symbol,
                            quantity: change.user_quantity ? change.user_quantity : 0,
                            amount: change.user_purchase_price ? change.user_purchase_price : change.percentage / 100 * props.accountValue,
                            allocation: change.percentage,
                            planTradeId: change.tradeId,
                            isChanged: false,
                            isSaved: change.user_quantity ? true : false,
                            algoId: change.algoId,
                            displayName: change.display_name,
                            alertName: change.name,
                            tradeSymbol: change.trade_symbol,
                            isShort: change.short
                        })
                    } else {
                        result.push({
                            action: change.action,
                            symbol: change.user_option_symbol,
                            savedSymbol: change.user_option_symbol,
                            quantity: change.user_quantity,
                            amount: change.user_quantity,
                            planTradeId: change.tradeId,
                            isChanged: false,
                            isSaved: change.user_quantity ? true : false,
                            algoId: change.algoId,
                            displayName: change.display_name,
                            tradeSymbol: change.trade_symbol,
                            allocation: change.percentage,
                            isShort: change.short
                        })
                    }
                }
                console.log("optionTrades from allocations", result);
                return result;
            }

            async function loadBrokerageConnections(userId) {
                console.log("Checking for active brokerage connections");
                let connections = await ((await fetch(`/api/brokeragesActive?userId=` + userId)).json());
                console.log("brokerage connections", connections);
                setBrokerageConnections(connections);
            }

            async function loadPlanInfo(planId) {
                let planInfo = await ((await fetch(`/api/planInfo?planId=` + planId)).json());
                if (planInfo && !planInfo.error) {
                    console.log("planInfo", planInfo);
                    setPlanInfo(planInfo);
                }
            }

            const loadTrades = async () => {
                console.log("Loading trades");
                var sendDate = (new Date()).getTime();
                setIsLoading(true);
                const url = `/api/newTrades?planId=` + props.planId + `&userId=` + props.userId + `&accountId=` + props.accountId;
                let trades = await (await fetch(url)).json();
                console.log("newTrades", trades);

                /*
                trades.instructions = [];
                trades.instructions.push({ action: 'Add', symbol: 'QQQ', amount: 5 })
                trades.allocationChanges = [];
                trades.allocationChanges.push({ base_symbol: "QQQ", name: 'crazyibs', baseClose: 510, action: 'buy', percentage: 5 })
                */

                var receiveDate = (new Date()).getTime();
                var responseTimeMs = receiveDate - sendDate;
                console.log("newTrades took " + responseTimeMs + " ms");
                if (trades && trades.instructions) setTrades(trades.instructions);
                if (trades && trades.date) setTradeDate(new Date(trades.date));
                if (trades && trades.closedTrades) setClosedTrades(trades.closedTrades);

                if (props.isOptions && trades && trades.allocationChanges && trades.allocationChanges.length) {
                    let optionTrades = await loadOptionTrades(trades.allocationChanges);
                    setOptionTrades(optionTrades);
                } else {
                    setOptionTrades(null);
                }
                setIsLoading(false);
            }

            const loadMarketHours = async () => {
                const url = `/api/marketHours`;
                const hours = await (await fetch(url)).json();
                return hours;
            }

            const updateTimer = async (marketHours) => {
                Settings.defaultZone = "America/New_York";
                let now = DateTime.now();
                let marketClose = DateTime.now();
                if (marketHours && marketHours.hour) {
                    marketClose = marketClose.set({ hour: marketHours.hour, minute: marketHours.minute + 4, second: 0, millisecond: 0 });
                } else {
                    marketClose = marketClose.set({ hour: 16, minute: 4, second: 0, millisecond: 0 });
                }
                let i = marketClose.diff(now);

                setRemainingTime(i);
                return i.milliseconds;
            }

            let hours = await loadMarketHours();
            setMarketHours(hours);

            loadTrades();

            loadBrokerageConnections(props.userId);

            loadPlanInfo(props.planId);

            const msUntilMarketClose = await updateTimer();

            if (timerRef.current) clearInterval(timerRef.current);
            console.log("Time until trades available", msUntilMarketClose);
            if (msUntilMarketClose > 0) {
                const id = setInterval(async (hours) => {
                    const ms = await updateTimer(hours);
                    if (ms <= 0) {
                        console.log("Reloading trades because timer expired", ms);
                        loadTrades();
                        loadPlanInfo(props.planId);
                        clearInterval(timerRef.current);
                    }
                }, 1000, hours);
                timerRef.current = id;
            }
        })();
    }, [props.planId, props.accountValue, props.accountId, props.isOptions, props.userId, props.brokerage]);

    function getInstructionText(instruction) {
        if (instruction.action === "Reduce") {
            let shares = Math.round(props.accountValue / instruction.price * (instruction.amount / 100));
            return instruction.action + " " + instruction.symbol + " by " + instruction.amount + "% (" + shares + " shares)";
        } else if (instruction.action === "Add") {
            let shares = Math.max(1, Math.round(props.accountValue / instruction.price * (instruction.amount / 100)));
            return instruction.action + " " + instruction.amount + "% to " + instruction.symbol + " (" + shares + " shares)";
        } else if (instruction.action === "Close") {
            return "Sell all shares of " + instruction.symbol;
        } else {
            return "Unknown action";
        };
    }

    function getAutomateTrades() {
        if (trades) {
            let result = [];
            trades.forEach(trade => {
                let shares = Math.round(props.accountValue / trade.price * (trade.amount / 100));
                let instruction = {
                    symbol: trade.symbol,
                    shares: trade.action === "Add" ? shares : shares * -1,
                }
                result.push(instruction);
            });
            return result;
        } else {
            return [];
        }
    }

    function renderProfit() {
        if (closedTrades && closedTrades.netProfit) {
            let dollarProfit = TableHelpers.moneyFormatterNoCents(props.accountValue * closedTrades.netProfit / 100);
            return (<div>
                * There were <Link to="/trades/completed">completed trades</Link> that resulted in a net profit of {closedTrades.netProfit.toFixed(2)}% (roughly {dollarProfit})
            </div>
            )
        } else
            return null;
    }

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    function renderNoTrades() {
        let now = DateTime.now();

        if (now.isWeekend || (marketHours && marketHours.isClosed)) {
            return <>The market is closed today</>
        } else {
            const hoursForCountdown = 9;
            if (remainingTime && remainingTime.milliseconds > 0 && remainingTime.milliseconds < (1000 * 60 * 60 * hoursForCountdown)) {
                let hours = Math.floor(remainingTime.milliseconds / 1000 / 60 / 60)
                let minutes = Math.floor((remainingTime.milliseconds / 1000 / 60) % 60)
                let seconds = Math.floor(((remainingTime.milliseconds / 1000) % 60));
                let remaining = hours + ":" +
                    zeroPad(minutes, 2) + ":" +
                    zeroPad(seconds, 2);

                const disablePeekMinutes = 7;
                return (
                    <>Coming in {remaining}... <br></br>
                        {remainingTime && remainingTime.milliseconds > 1000 * 60 * disablePeekMinutes ?
                            <Tooltip width={'500px'} textClass="tooltip-link smaller-text" text="Take a sneak peek 🔍" position={props.popupLocation ? props.popupLocation : "right center"}>
                                <SneakPeek planId={props.planId} />
                            </Tooltip>
                            : null
                        }

                    </>
                )
            } else {
                return <>No trades</>
            }
        }

    }

    function getOptionTrades() {
        if (optionTrades && optionTrades.length) {
            return optionTrades.map(trade => {
                return {
                    symbol: trade.symbol ? trade.symbol.toUpperCase() : null,
                    savedSymbol: trade.savedSymbol,
                    amount: trade.amount ? trade.amount : null,
                    quantity: trade.quantity,
                    action: trade.action,
                    planTradeId: trade.planTradeId,
                    isSaved: trade.isSaved,
                    isChanged: trade.isChanged,
                    algoId: trade.algoId,
                    alertName: trade.alertName,
                    alertSymbol: trade.underlyingSymbol,
                    displayName: trade.displayName,
                    tradeSymbol: trade.tradeSymbol,
                    allocation: trade.allocation,
                    isShort: trade.isShort
                }
            })
        } else {
            return [];
        }
    }

    async function updateOptionUserTrade(planTradeId, symbol, quantity, amount) {
        let trades = JSON.parse(JSON.stringify(optionTrades));
        let trade = trades.find(trade => trade.planTradeId === planTradeId);
        if (trade) {
            trade.symbol = symbol;
            trade.amount = amount;
            trade.quantity = quantity;
            trade.isSaved = false;
            trade.isChanged = true;
            setOptionTrades(trades);
        }
    }

    async function saveOptionUserTrade(planTradeId, shouldDelete) {

        let trades = JSON.parse(JSON.stringify(optionTrades));
        let trade = trades.find(trade => trade.planTradeId === planTradeId);
        if (trade) {
            let url = `/api/userTrade?isOption=1&userId=` + props.userId;
            url += "&planTradeId=" + planTradeId
            url += "&accountId=" + props.accountId
            url += "&option=" + trade.symbol
            url += "&shares=" + (shouldDelete ? 0 : trade.quantity);
            url += "&purchasePrice=" + (shouldDelete ? 0 : trade.amount);
            url += "&sellPrice=" + (shouldDelete ? 0 : trade.amount);
            console.log(url);
            await (await fetch(url, { method: 'POST' })).json();
            if (shouldDelete) {
                trade.isSaved = false;
            } else
                trade.isSaved = true;
            trade.isChanged = false;
            trade.savedSymbol = trade.symbol;
            setOptionTrades(trades);
        }
    }

    function algoLinkFormatter(cell, row) {
        return (
            <>{row.tradeSymbol}<Link className="bg-transparent" to={"/algo/" + cell}> {row.displayName}</Link ></>
        )
    }

    function onOptionSelected(planTradeId, optionSymbol, price) {
        console.log("onOptionSelected", planTradeId, optionSymbol)

        updateOptionUserTrade(planTradeId, optionSymbol, 0, price)
    }

    function optionSymbolFormatter(cell, row) {
        if (row.action === 'buy') {
            return <>
                <input size={25} type="text" defaultValue={row.savedSymbol} value={cell} onChange={(e) => updateOptionUserTrade(row.planTradeId, e.target.value, row.quantity, row.amount)}></input>
                {brokerageConnections && brokerageConnections.length ?
                    <Tooltip closeOnDocumentClick={true} width="600px" text=" 🔍" position={"left center"} >
                        <>
                            <OptionChain userId={props.userId} brokerage={brokerageConnections[0].brokerage} alertName={row.alertName} alertSymbol={row.alertSymbol} isShort={row.isShort} maxPrice={props.accountValue * row.allocation / 100 / 100} onOptionSelected={onOptionSelected} planTradeId={row.planTradeId} />
                        </>
                    </Tooltip>
                    :
                    null}
            </>
        } else {
            if (row.isSaved)
                return <>{cell}</>
            else
                return <>-</>
        }
    }

    function openPlaceTradeModal(symbol, quantity) {
        setTrade({
            symbol: symbol,
            shares: Number(quantity)
        })
        setIsPlacingOrder(true);
    }

    function optionQuantityFormatter(cell, row) {
        if (row.action === 'buy')
            return <>
                <input size={7} type="text" value={Number(cell)} onChange={(e) => updateOptionUserTrade(row.planTradeId, row.symbol, e.target.value, row.amount)} /><Link onClick={() => openPlaceTradeModal(row.symbol, row.quantity)}> ⤴️</Link>
                &nbsp;({Math.round(props.accountValue * (row.allocation / 100) / (row.amount * 100))})
            </>
        else {
            if (row.isSaved)
                return <>{cell}</>
            else
                return <>-</>
        }
    }

    function optionPurchasePriceFormatter(cell, row) {
        if (row.action === 'buy')
            return <>$<input size={7} type="text" value={cell} onChange={(e) => updateOptionUserTrade(row.planTradeId, row.symbol, row.quantity, e.target.value)}></input></>
        else {
            if (row.isSaved)
                return <>-</>
        }
    }

    function saveOptionButtonFormatter(cell, row) {
        if (row.action === 'buy')
            return <><input type='checkbox' checked={row.isSaved} onClick={() => saveOptionUserTrade(row.planTradeId, row.isSaved)} />{row.isChanged ? " Not saved" : ""}</>
        else {
            if (row.isSaved)
                return <>✅</>
            else
                return <>Skipped</>
        }
    }

    return (

        <div>
            {!isLoading ?
                <>
                    {props.hideHeader ? null :
                        <h4>
                            Trades for {tradeDate ? (tradeDate.getUTCMonth() + 1) + "/" + tradeDate.getUTCDate() + "/" + (tradeDate.getUTCFullYear() - 2000) : "Unknown"}
                        </h4>
                    }
                    {planInfo && planInfo.trading_disabled ?
                        <><br></br><b>⛔️ Circuit breaker triggered. The plan will remain in cash until the circuit breaker is turned off ⛔️</b><br></br><br></br></>
                        : null}
                    {trades && trades.length ?
                        <>
                            {props.isOptions ?
                                <>
                                    <RichTable headless={false} data={getOptionTrades()} className="table-condensed" mappers={[
                                        { title: 'Action', field: 'action' },
                                        { title: 'Quantity', field: 'quantity', formatter: optionQuantityFormatter },
                                        { title: 'Per Contract', field: 'amount', formatter: optionPurchasePriceFormatter },
                                        { title: 'Option', field: 'symbol', formatter: optionSymbolFormatter },
                                        { title: 'Algo', field: 'algoId', formatter: algoLinkFormatter },
                                        { title: 'Saved', field: 'isSaved', formatter: saveOptionButtonFormatter },
                                    ]} />
                                </>
                                :
                                <>
                                    <RichTable headless={true} data={trades} className="table-condensed" mappers={[
                                        { title: 'Action', field: 'text', isDefaultSort: true, sorters: createDefaultSorter('symbol'), formatter: (_, r) => getInstructionText(r) },
                                    ]} />
                                </>
                            }

                            {props.hideProfit || props.isOptions ? null : renderProfit()}
                        </>
                        :
                        <>
                            <br></br>
                            {renderNoTrades()}
                        </>
                    }
                </>
                :
                <LinearProgress />
            }
            {
                props.brokerage && props.brokerageHref && brokerageConnections.length === 0 ?
                    <><br></br><Link to={props.brokerageHref + "&state=trade"}>🛜 Login to {props.brokerage}</Link></>
                    :
                    null
            }

            {
                trades && trades.length && props.isOptions && brokerageConnections.length === 0 ?
                    <><br></br>Authenticate with brokerage for option chains</>
                    :
                    null
            }

            {
                props.brokerage && brokerageConnections.length ?
                    <><br></br><Link onClick={() => setIsAutomatedTrades(true)}>🟢 Trade at {brokerageConnections[0].brokerage}</Link></>
                    :
                    null
            }

            <AutomateTrades brokerage={props.brokerage} trades={getAutomateTrades()} userId={props.userId} accountId={props.accountId} isOpen={isAutomatedTrades} onClose={() => setIsAutomatedTrades(false)} mobileView={props.mobileView} />
            <AutomateTrade brokerage={props.brokerage} trade={trade} userId={props.userId} accountId={props.accountId} isOpen={isPlacingOrder} onClose={() => setIsPlacingOrder(false)} mobileView={props.mobileView} />

        </div >
    );
}

export default TradeInstructions;