import React, { useState, useEffect } from 'react';
import tableHelpers from '../helpers/TableHelpers.js'
import RichTable from '../components/richTable/RichTable.jsx'
import BacktestResultsHelpers from '../helpers/BacktestResultsHelpers.js';
import DailyBalanceChart from './DailyBalanceChart.js';
import DailyProfitChart from './DailyProfitChart.js';

function CircuitBreakerAnalysis(props) {

    const [backtestResults, setBacktestResults] = useState({});
    const [liveResults, setLiveResults] = useState({});
    const [chartData, setChartData] = useState([]);
    const [liveChartDate, setLiveChartData] = useState([]);
    const [minimumDrawdown, setMinimumDrawdown] = useState(5)

    useEffect(() => {
        (async function () {
            const url = `/api/lastBacktestResults?planId=` + props.planId + "&liveTradesOnly=0";
            let text = await (await fetch(url)).json();
            console.log("backtest", text);
            setBacktestResults(text);

            const tempChartData = [];//[["Date", "Account Value", "S&P 500"]]
            text.dailyResults.forEach(day => tempChartData.push([new Date(day.date), day.total, day.baseline]))
            setChartData(tempChartData)

            const liveUrl = `/api/lastBacktestResults?planId=` + props.planId + "&liveTradesOnly=1";
            let liveText = await (await fetch(liveUrl)).json();
            console.log("live", liveText);
            setLiveResults(liveText);

            const liveData = [];//[["Date", "Account Value", "S&P 500"]]
            liveText.dailyResults.forEach(day => liveData.push([new Date(day.date), day.total, day.baseline]))
            setLiveChartData(liveData)

        })();
    }, [props.planId]);

    function daysBetween(first, second) {
        return Math.round((second.getTime() - first.getTime()) / (1000 * 60 * 60 * 24));
    }

    function getExampleRows(example) {

        return [
            {
                name: 'DD Start Date',
                value: new Date(example.drawdownStartDate),
                format: 'date'
            },
            {
                name: 'Max DD Date',
                value: new Date(example.maxDrawdownDate),
                format: 'date'
            },
            {
                name: 'DD End Date',
                value: new Date(example.endDate),
                format: 'date'
            },
            {
                name: 'Max DD',
                value: example.maxDrawdown,
                format: 'percent'
            },
            {
                name: 'Total DD duration',
                value: example.drawdownDuration,
                format: 'int'
            },
            {
                name: 'Start to Bottom Duration',
                value: example.daysFromStartToBottom,
                format: 'int'
            },
            {
                name: 'Bottom to Breakeven Duration',
                value: example.daysFromBottomToBreakeven,
                format: 'int'
            },
            {
                name: 'One Week Return',
                value: example.oneWeek,
                format: 'percent'
            },
            {
                name: 'Two Week Return',
                value: example.twoWeeks,
                format: 'percent'
            },
            {
                name: 'Four Week Return',
                value: example.fourWeeks,
                format: 'percent'
            },
        ];
    }

    function getReturnsAfterDays(dailyResults, startValue, startIndex, days) {
        let newValue = startIndex + days < dailyResults.length ? dailyResults[startIndex + days].total : null;
        if (newValue) {
            return (newValue - startValue) / startValue * 100;
        } else {
            return 0;
        }
    }

    function getSimilarExamples() {
        if (liveResults && backtestResults && liveResults.dailyResults && backtestResults.dailyResults && liveResults.dailyResults.length && backtestResults.dailyResults.length) {
            let startDate = BacktestResultsHelpers.getMaxBalanceDate(liveResults, false);
            let maxDrawdown = BacktestResultsHelpers.getMaxDrawdownSince(liveResults, new Date(startDate));
            let maxDrawdownDate = BacktestResultsHelpers.getMaxDrawdownDateSince(liveResults, new Date(startDate));
            let results = {
                currentDrawdown: BacktestResultsHelpers.getCurrentDrawdown(liveResults, false),
                maxDrawdown: maxDrawdown,
                maxDrawdownDate: maxDrawdownDate,
                daysSinceMaxDrawdownDate: daysBetween(new Date(maxDrawdownDate), new Date()),
                currentDrawdownStartDate: startDate,
                currentDrawdownDays: daysBetween(new Date(startDate), new Date()),
                examplesDrawdown: minimumDrawdown,
                oneWeekAvg: 0,
                twoWeekAvg: 0,
                fourWeekAvg: 0,
                daysUntilBreakevenAvg: 0,
                avgDrawdownDuration: 0,
                avgStartToBottomDuration: 0,
                avgBottomToBreakevenDuration: 0,
                avgMaxDrawdown: 0,
                maxBottomToBreakevenDuration: 0,
                examples: []
            };

            let max = 0;
            let maxDate = 0;
            let currentExample = null;

            for (let i = 0; i < backtestResults.dailyResults.length; i++) {
                let dailyResult = backtestResults.dailyResults[i];

                if (dailyResult.total > max) {
                    max = dailyResult.total;
                    maxDate = dailyResult.date;
                }

                let drawdown = (max - dailyResult.total) / max * 100;

                if (currentExample === null && drawdown > results.examplesDrawdown) {
                    currentExample = {
                        maxDrawdown: drawdown,
                        maxDrawdownDate: dailyResult.date,
                        startDate: dailyResult.date,
                        drawdownStartDate: maxDate,
                        daysFromStartToBottom: daysBetween(new Date(maxDate), new Date(dailyResult.date)),
                        endDate: null,
                        oneWeek: getReturnsAfterDays(backtestResults.dailyResults, dailyResult.total, i, 5),
                        twoWeeks: getReturnsAfterDays(backtestResults.dailyResults, dailyResult.total, i, 10),
                        fourWeeks: getReturnsAfterDays(backtestResults.dailyResults, dailyResult.total, i, 20),
                        recoveryDays: [drawdown],
                        drawdownDuration: 0
                    }
                    console.log("New example", new Date(dailyResult.date), currentExample);
                } else if (dailyResult.total >= max && currentExample) {
                    currentExample.endDate = dailyResult.date
                    currentExample.drawdownDuration = daysBetween(new Date(currentExample.drawdownStartDate), new Date(currentExample.endDate))
                    currentExample.daysFromBottomToBreakeven = daysBetween(new Date(currentExample.maxDrawdownDate), new Date(dailyResult.date))
                    results.examples.push(currentExample)
                    currentExample = null;
                } else if (currentExample) {
                    currentExample.recoveryDays.push(drawdown);
                    if (drawdown > currentExample.maxDrawdown) {
                        currentExample.maxDrawdown = drawdown;
                        currentExample.maxDrawdownDate = dailyResult.date;
                        currentExample.daysFromStartToBottom = daysBetween(new Date(currentExample.drawdownStartDate), new Date(dailyResult.date));
                        currentExample.recoveryDays = [drawdown];
                        currentExample.oneWeek = getReturnsAfterDays(backtestResults.dailyResults, dailyResult.total, i, 5);
                        currentExample.twoWeeks = getReturnsAfterDays(backtestResults.dailyResults, dailyResult.total, i, 10);
                        currentExample.fourWeeks = getReturnsAfterDays(backtestResults.dailyResults, dailyResult.total, i, 20);
                    }
                }
            }

            let counter = 0;
            results.count = results.examples.length;
            results.maxDuration = 0;
            results.maxBottomToBreakevenDuration = 0;
            results.examples.forEach(example => {
                results.oneWeekAvg = ((results.oneWeekAvg * counter) + example.oneWeek) / (counter + 1);
                results.twoWeekAvg = ((results.twoWeekAvg * counter) + example.twoWeeks) / (counter + 1);
                results.fourWeekAvg = ((results.fourWeekAvg * counter) + example.fourWeeks) / (counter + 1);
                results.daysUntilBreakevenAvg = ((results.daysUntilBreakevenAvg * counter) + example.daysFromBottomToBreakeven) / (counter + 1);
                results.avgDrawdownDuration = ((results.avgDrawdownDuration * counter) + example.drawdownDuration) / (counter + 1);
                results.avgStartToBottomDuration = ((results.avgStartToBottomDuration * counter) + example.daysFromStartToBottom) / (counter + 1);
                results.avgBottomToBreakevenDuration = ((results.avgBottomToBreakevenDuration * counter) + example.daysFromBottomToBreakeven) / (counter + 1);
                results.avgMaxDrawdown = ((results.avgMaxDrawdown * counter) + example.maxDrawdown) / (counter + 1);
                if (example.drawdownDuration > results.maxDuration)
                    results.maxDuration = example.drawdownDuration;
                if (example.daysFromBottomToBreakeven > results.maxBottomToBreakevenDuration)
                    results.maxBottomToBreakevenDuration = example.daysFromBottomToBreakeven;
                ++counter;
            })

            return results;
        } else {
            return null;
        }
    }

    let examples = getSimilarExamples();
    console.log("examples", examples);

    return (
        <>
            <div>
                {examples ?
                    <>
                        <br></br>
                        <RichTable data={[examples]} className="table-striped table-hover table-condensed" mappers={
                            [
                                { title: 'Current Start Date', field: 'currentDrawdownStartDate', formatter: tableHelpers.dateFormmatter },
                                { title: 'Current Duration', field: 'currentDrawdownDays', formatter: tableHelpers.intFormatter },
                                { title: 'Bottom of Current DD', field: 'maxDrawdown', formatter: tableHelpers.percentFormatterOneDecimal },
                                { title: 'Days Since Bottom', field: 'daysSinceMaxDrawdownDate', formatter: tableHelpers.intFormatter },
                            ]
                        } />
                        <DailyBalanceChart hideAllocationChart={true} hideOptions={true} hideChartSummary={true} width="100%" startDate={new Date(examples.currentDrawdownStartDate).setMonth(new Date(examples.currentDrawdownStartDate).getMonth() - 2)} backtestResults={liveResults} chartData={liveChartDate}></DailyBalanceChart>
                        <DailyProfitChart planId={props.planId} backtestResults={liveResults} secondaryResults={backtestResults} />
                        <br></br>
                        <hr style={{ height: "5px", backgroundColor: 'black' }}></hr>
                        <center>
                            <h4>
                                Current Drawdown: {tableHelpers.percentFormatterOneDecimal(examples.currentDrawdown, null)}
                            </h4>
                            <h4>
                                Showing all drawdowns greater than <input type="text" defaultValue={examples.examplesDrawdown} size={3} onChange={(e) => setMinimumDrawdown(e.target.value)}></input>%
                            </h4>
                        </center>

                        <center>
                            <h4>Similar Drawdowns</h4>
                            <RichTable data={[examples]} className="table-striped table-hover table-condensed" mappers={
                                [
                                    { title: 'Count', field: 'count', formatter: tableHelpers.intFormatter },
                                    { title: 'Avg Max DD', field: 'avgMaxDrawdown', formatter: tableHelpers.percentFormatterOneDecimal },
                                    { title: 'Avg Duration', field: 'avgDrawdownDuration', formatter: tableHelpers.intFormatter },
                                    { title: 'Avg Start to Bottom', field: 'avgStartToBottomDuration', formatter: tableHelpers.intFormatter },
                                    { title: 'Avg Bottom to BE', field: 'avgBottomToBreakevenDuration', formatter: tableHelpers.intFormatter },
                                    { title: 'Max Duration', field: 'maxDuration', formatter: tableHelpers.intFormatter },
                                    { title: 'Max Bottom to BE', field: 'maxBottomToBreakevenDuration', formatter: tableHelpers.intFormatter },
                                ]
                            } />
                            <br></br>

                            <h4>Average Returns from Bottom</h4>
                            <div style={{ width: "500px" }} >
                                <RichTable data={[examples]} className="table-striped table-hover table-condensed" mappers={
                                    [
                                        { title: '1 Week', field: 'oneWeekAvg', formatter: tableHelpers.percentChangeFormatterOneDecimal },
                                        { title: '2 Weeks', field: 'twoWeekAvg', formatter: tableHelpers.percentChangeFormatterOneDecimal },
                                        { title: '4 Weeks', field: 'fourWeekAvg', formatter: tableHelpers.percentChangeFormatterOneDecimal },
                                    ]
                                } />
                            </div>
                        </center>
                        <hr style={{ height: "5px", backgroundColor: 'black' }}></hr>
                        <br></br>

                        <h4>Previous Drawdowns</h4>
                        {examples.examples.length > 0 && examples.examples.length < 50 ?
                            <>
                                {examples.examples.map(example => {
                                    return (
                                        <>
                                            <center>
                                                <table width="800px">
                                                    <tr>
                                                        <td width="500px">
                                                            <DailyBalanceChart hideAllocationChart={true} hideOptions={true} hideChartSummary={true} width="400px" startDate={new Date(example.drawdownStartDate).setMonth(new Date(example.drawdownStartDate).getMonth() - 1)} endDate={example.endDate ? new Date(example.endDate).setMonth(new Date(example.endDate).getMonth() + 1) : null} backtestResults={backtestResults} chartData={chartData}></DailyBalanceChart>
                                                        </td>
                                                        <td valign='center'>
                                                            <RichTable data={getExampleRows(example)} className="table-striped table-hover table-condensed" mappers={
                                                                [
                                                                    { title: 'Name', field: 'name' },
                                                                    { title: 'Value', field: 'value', formatter: tableHelpers.dynamicFormatter },
                                                                ]
                                                            } />
                                                        </td>
                                                    </tr>
                                                </table>
                                            </center>
                                            <hr style={{ height: "3px", backgroundColor: 'black' }}></hr>
                                        </>
                                    );
                                })}
                            </> :
                            <>No examples found</>}
                    </>
                    :
                    <>Loading...</>
                }

            </div >
        </>
    );
}

export default CircuitBreakerAnalysis; 