import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
import tableHelpers from '../helpers/TableHelpers.js'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable.jsx'


function StressTest(props) {
    const [startDate, setStartDate] = useState(new Date(2014, 0, 1))
    const [endDate, setEndDate] = useState(new Date())
    const [testRuns, setTestRuns] = useState(25);
    const [randomizeOrder, setRandomizeOrder] = useState(true);
    const [slippageRate, setSlippageRate] = useState(3);
    const [lossRate, setLossRate] = useState(3);
    const [skippedTrades, setSkippedTrades] = useState(3);
    const [loading, setLoading] = useState(false);
    const [simResults, setSimResults] = useState(null);

    useEffect(() => {
        (async function () {

        })();
    }, []);


    function getResults() {
        if (simResults.results.summary) {
            return [simResults.results.summary];
        } else {
            return [];
        }
    }

    function getChartData() {
        if (simResults && simResults.results && simResults.results.dailyResults) {
            let chartData = [];
            let header = null;

            let lastDay = null;
            simResults.results.dailyResults.forEach(day => {
                chartData.push([new Date(day.date), ...day.net])

                if (!header) {
                    header = ["Date"];
                    day.net.forEach(r => header.push("Simulation"))
                }
                lastDay = day;
            })

            chartData.unshift(header)
            return [chartData, 0, lastDay.max * 1.05];
        } else {
            return [[["Date"]], 0, 0];
        }
    }

    async function handleLoadSimulation() {
        console.log("Starting simulation");
        setLoading(true);
        const query = `/api/planMonteCarlo?planId=` + props.planId +
            "&startDate=" + startDate.toLocaleDateString() +
            "&endDate=" + endDate.toLocaleDateString() +
            "&extraLossRate=" + lossRate +
            "&slippageRate=" + slippageRate +
            "&shouldRandomizeOrder=" + (randomizeOrder ? "true" : "false") +
            "&skippedTradesRate=" + skippedTrades +
            "&simulations=" + testRuns

        let results = await (await fetch(query)).json();
        if (results.success)
            setSimResults(results);
        console.log(results);
        setLoading(false);
    }

    function handleYearRangeClicked(years) {
        let d = new Date();
        d.setFullYear(d.getFullYear() - years);
        setStartDate(d);
        setEndDate(new Date());
    }

    const resultsMapper = [
        { title: `Test Runs`, field: 'count', formatter: tableHelpers.intFormatter },
        { title: `Years`, field: 'years', formatter: tableHelpers.intFormatter },
        { title: `Avg Annual Profit`, field: 'annualProfit', formatter: tableHelpers.percentFormatter },
        { title: `Avg Monthly Profit`, field: 'monthlyProfit', formatter: tableHelpers.percentFormatter },
        { title: `Annual StdDev`, field: 'stddev', formatter: tableHelpers.percentFormatter },
        { title: `68% Confidence`, field: 'oneStdDev' },
        { title: `95% Confidence`, field: 'twoStdDev' },
        { title: `Min Profit`, field: 'minProfit', formatter: tableHelpers.percentFormatter },
        { title: `Max Profit`, field: 'maxProfit', formatter: tableHelpers.percentFormatter },
    ]

    let simulationChartData = getChartData();
    console.log("simulationChartData", simulationChartData);
    let chartData = simulationChartData[0];
    let minValue = simulationChartData[1];
    let maxValue = simulationChartData[2];
    const options = {
        title: "Realized P/L",
        vAxis: {
            viewWindowMode: 'explicit',
            viewWindow: {
                max: maxValue + (Math.abs(maxValue) * .1),
                min: minValue - (Math.abs(minValue) * .1)
            },
            minValue: maxValue + (Math.abs(maxValue) * .1),
            maxValue: minValue - (Math.abs(minValue) * .1)
        },
        chartArea: { 'width': '75%', 'height': '80%' },
        legend: { position: 'none' }
    };

    return (
        <>
            <br></br>
            <center>
                <h4>Data Sample</h4>
                Start Date: <DatePicker showYearPicker disabledKeyboardNavigation className="bg-transparent" selected={startDate} onChange={(date) => setStartDate(date)} />&nbsp;&nbsp;
                End Date: <DatePicker showYearPicker className="bg-transparent" selected={endDate} onChange={(date) => setEndDate(date)} />
                <br></br>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(1) }}>1 Year</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(2) }}>2 Years</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(3) }}>3 Years</Link>
                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(5) }}>5 Years</Link>
                <br></br>
                <br></br>
                Randomize Order:<input type="checkbox" defaultChecked={true} onChange={(e) => setRandomizeOrder(!randomizeOrder)}></input>&nbsp;&nbsp;
                P/L Slippage %: <input type="text" size="3" value={slippageRate} onChange={(e) => setSlippageRate(e.target.value)}></input>&nbsp;&nbsp;
                Extra Loss Rate %: <input type="text" size="3" value={lossRate} onChange={(e) => setLossRate(e.target.value)}></input>&nbsp;&nbsp;
                Skipped Winners %: <input type="text" size="3" value={skippedTrades} onChange={(e) => setSkippedTrades(e.target.value)}></input>&nbsp;&nbsp;
                <br></br>
                <div class="sameline">
                    Simulations: <input type="text" size={3} value={testRuns} onChange={(e) => setTestRuns(e.target.value)}></input>
                    &nbsp;<Button disabled={loading} className="btn btn-primary" onClick={() => handleLoadSimulation()}>{loading ? "Loading" : "Run"}</Button>
                </div>
            </center>

            {chartData.length <= 1 ?
                <></>
                :
                <div>
                    <center>
                        <Chart
                            chartType="LineChart"
                            data={chartData}
                            width={"100%"}
                            height={"400px"}
                            options={options}
                            chartPackages={["corechart", "controls"]}
                        />
                    </center>
                </div>
            }
            {simResults ?
                <RichTable data={getResults()} mappers={resultsMapper} className="table-striped table-hover table-condensed" />
                :
                null
            }
        </>
    );
}

export default StressTest;