import React, { useState, useEffect } from 'react';
import RichTable from '../components/richTable/RichTable.jsx'
import Tooltip from '../components/Tooltip.js'
import StickySettings from '../helpers/StickySettings.js'
import { Link } from 'react-router-dom'

function AlertList(props) {
    const [data, setData] = useState([]);
    const [algos, setAlgos] = useState([]);
    const [shouldFilterPlan, setShouldFilterPlan] = useState(StickySettings.getBool('alertsFilterByPlan'));
    const [planAlgos, setPlanAlgos] = useState([])

    useEffect(() => {
        (async function () {

            const algoText = await (await fetch(`/api/algos?planId=` + props.planId)).json();
            setPlanAlgos(algoText);

            let text = await (await fetch(`/api/strategyAlerts`)).json();
            if (text && Array.isArray(text)) {
                text.sort((a, b) => a.symbol < b.symbol ? -1 : 1)
            }
            setData(text);

            const algos = await (await fetch(`/api/algoChildren`)).json();
            setAlgos(algos);
        })();
    }, [props.planId]);

    function getFilterAlerts() {
        let alerts = data;
        if (shouldFilterPlan) {
            alerts = alerts.filter(a => {
                return planAlgos.find(algo => algo.symbol === a.symbol && algo.name === a.algo_name) !== undefined
            })
        }
        return alerts;
    }

    function getAlerts() {

        const alerts = getFilterAlerts();

        return alerts.map(alert => {
            return {
                id: alert.idtrading_strategy_alerts,
                strategyId: alert.strategyId,
                strategyName: alert.name,
                algoName: alert.algo_name,
                symbol: alert.symbol,
                timeframe: alert.timeframe,
                config: alert.config,
            }
        });
    }

    function onAlertClicked(alertId) {
        if (props.onAlertClicked)
            props.onAlertClicked(alertId);
    }

    function alertLink(cell, row) {

        let childrenDisplay = {};
        const children = algos.filter(a => a.name === row.algoName && a.symbol === row.symbol);
        let displayName = cell;
        if (children) {
            children.forEach(c => {
                displayName = c.display_name;
                childrenDisplay[c.plan_name] = c.percentage + "%";
            })
        }

        return (
            <>
                <Link className="bg-transparent" to={"/configureAlert/" + row.id} onClick={() => onAlertClicked(row.id)}>{displayName}</Link >
                <Tooltip text={" 🔍"} json={row.config} />
            </>
        )
    }

    function onPlanFilterSelected() {
        console.log("onPlanFilterSelected");
        StickySettings.setBool('alertsFilterByPlan', !shouldFilterPlan);
        setShouldFilterPlan(!shouldFilterPlan);
    }

    return (
        <>
            <hr></hr>
            <h3>Alerts</h3>
            <div className="sameline">
                <input type='checkbox' checked={shouldFilterPlan} onClick={() => onPlanFilterSelected()} />Plan only
            </div>
            <br></br>

            <RichTable data={getAlerts()} className="table-striped table-hover table-condensed" mappers={
                [
                    { title: 'Algo Name', field: 'algoName', formatter: alertLink, filter: 'text', sorters: true },
                    { title: 'Symbol', field: 'symbol', sorters: true, filter: 'text' },
                ]
            } />
        </>
    );
}

export default AlertList;