import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import Dropdown from 'react-bootstrap/Dropdown';

function AlgoAverageTrade(props) {


    const [data, setData] = useState(null);
    const [view, setView] = useState('mean');
    const [filter, setFilter] = useState('All trades');

    useEffect(() => {
        (async function () {

            let url = `/api/algoPriceAnalysis?algoId=` + props.algoId + `&filter=` + filter
            if (props.planTradeId) {
                url += "&planTradeId=" + props.planTradeId
            }
            let data = await ((await fetch(url)).json());
            setData(data);
        })();
    }, [props.algoId, props.planTradeId, filter]);

    function getChartData() {
        if (data && data.changes && data.changes.length) {
            let results = [
                ["Day",
                    "Performance",
                    { id: "stdDev", type: "number", role: "interval" },
                    { id: "stdDev", type: "number", role: "interval" },
                    { id: "stdDev2", type: "number", role: "interval" },
                    { id: "stdDev2", type: "number", role: "interval" },
                    { id: "percentActive", role: "annotation", type: "string", style: { color: 'black' } },
                    { id: "winners", type: "number", role: "interval" },
                    { id: "losers", type: "number", role: "interval" }
                ]
            ];
            let includeActiveTrade = false;
            if (data.changes && data.changes.length && data.changes[0].activeTrade !== undefined && !props.hideActiveTrade) {
                results[0].push("Trade");
                includeActiveTrade = true;
            }
            let day = 0;
            let annotationsMod = data.changes.length > 50 ? 10 : data.changes.length > 20 ? 5 : data.changes.length < 10 ? 1 : 2;
            data.changes.forEach(change => {
                let value = view === 'median' ? change.median : change.mean;
                let upperRange = value + change.stdDev / 2
                let lowerRange = value - change.stdDev / 2
                let upperRange2 = value + change.stdDev
                let lowerRange2 = value - change.stdDev
                let percentActive = change.count / data.count * 100;
                results.push([day,
                    value / 100,
                    upperRange / 100,
                    lowerRange / 100,
                    upperRange2 / 100,
                    lowerRange2 / 100,
                    day % annotationsMod === 0 ? percentActive.toFixed(0) + "%" : null,
                    day === data.avgDurationOfWinners ? data.avgWin / 100 : null,
                    day === data.avgDurationOfLosers ? data.avgLoss / 100 : null
                ])
                if (includeActiveTrade) {
                    results[results.length - 1].push(
                        change.activeTrade !== undefined ? change.activeTrade / 100 : undefined
                    )
                }
                ++day;
            })
            return results;
        } else {
            return null;
        }
    }

    return (
        <>
            <div className="sameline" style={{ padding: "20px" }}>
                <Dropdown onSelect={(e) => setFilter(e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {filter}
                    </Dropdown.Toggle>
                    <Dropdown.Menu  >
                        <Dropdown.Item as='filterId' eventKey={'all'} value={'all'}>All trades</Dropdown.Item>
                        <Dropdown.Item as='filterId' eventKey={'winners'} value={'winners'}>Winners Only</Dropdown.Item>
                        <Dropdown.Item as='filterId' eventKey={'losers'} value={'losers'}>Losers Only</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown onSelect={(e) => setView(e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {view}
                    </Dropdown.Toggle>
                    <Dropdown.Menu  >
                        <Dropdown.Item as='viewId' eventKey={'mean'} value={'mean'}>mean</Dropdown.Item>
                        <Dropdown.Item as='viewId' eventKey={'median'} value={'median'}>median</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            {data ?
                <Chart
                    chartType="LineChart"
                    data={getChartData()}
                    width={"100%"}
                    height={"400px"}
                    chartPackages={["corechart", "controls"]}
                    options={{
                        curveType: "none",
                        legend: { position: "bottom" },
                        chartArea: { 'width': '90%', 'height': '80%' },
                        interval: {
                            winners: {
                                color: "#B2D8B2",
                                style: "bars",
                                barWidth: 0,
                                lineWidth: 4,
                                pointSize: 10,
                                fillOpacity: 1,
                            },
                            losers: {
                                color: "#B85D5D",
                                style: "bars",
                                barWidth: 0,
                                lineWidth: 4,
                                pointSize: 10,
                                fillOpacity: 1,
                            },
                            stdDev: {
                                style: "area"
                            },
                            stdDev2: {
                                style: "area"
                            },
                        },
                        annotations: {
                            textStyle: {
                                fontSize: 12,
                                color: 'black',
                            },
                        },
                        vAxis: {
                            format: 'percent'
                        }
                    }}
                />
                :
                null}
        </>
    );
}

export default AlgoAverageTrade;