import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import { Link } from 'react-router-dom'

function getOneYearAgo() {
    let date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    return date;
}

function StockChart(props) {

    const [quoteHistory, setQuoteHistory] = useState([]);
    const [tradeHistory, setTradeHistory] = useState([]);
    const [startDate, setStartDate] = useState(getOneYearAgo())
    const [endDate, setEndDate] = useState(new Date())
    const [symbol, setSymbol] = useState(null);

    useEffect(() => {
        (async function () {

            if (props.symbol && props.symbol !== symbol) {
                setSymbol(props.symbol);
                const url = `/api/quoteHistory?symbol=` + props.symbol +
                    "&startDate=2006-1-1" +
                    "&endDate=" + new Date().toLocaleDateString();
                let history = await (await fetch(url)).json();
                history.reverse();
                setQuoteHistory(history);
            }

            if (props.trades) {
                setTradeHistory(props.trades);
            }
        })();
    }, [props.symbol, symbol, props.trades]);

    function handleYearRangeClicked(years) {
        let d = new Date();
        d.setFullYear(d.getFullYear() - years);
        setStartDate(d);
        setEndDate(new Date())
    }

    function handleYearClicked(year) {
        let start = new Date(year, 0, 1);
        let end = new Date(year, 11, 31);
        setStartDate(start);
        setEndDate(end);
    }

    function getChartData() {

        let retVal = {
            maxPrice: 0,
            minPrice: 10000000,
            data: []
        };

        if (quoteHistory && quoteHistory.length) {
            quoteHistory.forEach(quote => {
                let long = 0;
                const date = new Date(quote.date);
                if (date >= startDate && date <= endDate) {
                    if (quote.close > retVal.maxPrice) retVal.maxPrice = quote.close;
                    if (quote.close < retVal.minPrice) retVal.minPrice = quote.close;

                    tradeHistory.forEach(trade => {
                        const buyDate = new Date(trade.purchase_date);
                        const sellDate = trade.sell_date ? new Date(trade.sell_date) : new Date();
                        if (date >= buyDate && date <= sellDate) long = quote.close;
                    })
                    retVal.data.push([(date.getUTCMonth() + 1) + "/" + date.getUTCDate() + "/" + (date.getUTCFullYear() - 2000),
                    quote.close, long]);
                }
            })
        }

        if (retVal.data.length)
            retVal.data.unshift(['Date', 'Price', 'Long']);

        return retVal;
    }

    let chartData = getChartData();

    const options = {
        vAxis: {
            viewWindowMode: 'explicit',
            viewWindow: {
                max: chartData.maxPrice * 1.05,
                min: chartData.minPrice * .9
            },
            minValue: chartData.minPrice * .9,
            maxValue: chartData.maxPrice * 1.05
        },
        chartArea: { 'width': '90%', 'height': '75%' },
        legend: { position: "none" },
    };

    return (
        <>
            <table>
                <tr>
                    <td>
                        {
                            chartData.data.length ?
                                <Chart
                                    chartType="AreaChart"
                                    data={chartData.data}
                                    options={options}
                                    height={props.height ? props.height : "400px"}
                                    width={props.width ? props.width : "600px"}
                                    chartPackages={["corechart", "controls"]}
                                />
                                :
                                null
                        }
                    </td></tr>
                <tr style={{ backgroundColor: 'transparent' }}>
                    <td>
                        {props.hideTimeframeSelection ? null :
                            <center>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(1) }}>1 Year</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(2) }}>2 Years</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(3) }}>3 Years</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(5) }}>5 Years</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearRangeClicked(10) }}>10 Years</Link>
                                <br></br>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2025) }}>2025</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2024) }}>2024</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2023) }}>2023</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2022) }}>2022</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2021) }}>2021</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2020) }}>2020</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2019) }}>2019</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2018) }}>2018</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2017) }}>2017</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2016) }}>2016</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2015) }}>2015</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2014) }}>2014</Link>
                                <br></br>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2013) }}>2013</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2012) }}>2012</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2011) }}>2011</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2010) }}>2010</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2009) }}>2009</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2008) }}>2008</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2007) }}>2007</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2006) }}>2006</Link>
                                &nbsp;&nbsp;<Link id="oneweek" className="menu-item" onClick={e => { handleYearClicked(2005) }}>2005</Link>

                            </center>
                        }
                    </td>
                </tr>
            </table>
        </>
    );
}

export default StockChart;