import React, { useState, useEffect } from 'react';
import tableHelpers from '../helpers/TableHelpers.js'
import RichTable from '../components/richTable/RichTable.jsx'
import { createDateSorter } from '../components/richTable/sorters.js'
import Tooltip from '../components/Tooltip.js'

function AlertTrades(props) {

    const [performance, setPerformance] = useState({})

    useEffect(() => {
        (async function () {
            if (props.performance)
                setPerformance(props.performance);
        })();
    }, [props.alertId, props.performance]);

    function getTrades() {
        if (performance && performance.trades && performance.trades.length) {
            let id = 0;
            return performance.trades.map(t => {
                return {
                    id: ++id,
                    purchaseDate: t.purchaseDate,
                    purchasePrice: t.purchasePrice,
                    purchaseData: t.purchaseData,
                    sellDate: t.sellDate,
                    sellPrice: t.sellPrice,
                    sellData: t.sellData,
                    profitPercentage: t.profitPercentage
                }
            })
        } else {
            return [];
        }
    }

    function purchaseDateFormatter(cell, row) {
        return (
            <>
                {tableHelpers.dateFormmatter(cell, row)}

                &nbsp;&nbsp;<Tooltip textClass={"tooltip-link"} text={"🔍"} json={row.purchaseData} width={360} borderWidth={"1px"} position="right center" >
                </Tooltip >
            </>
        )
    }

    function sellDateFormatter(cell, row) {
        return (
            <>
                {tableHelpers.dateFormmatter(cell, row)}

                &nbsp;&nbsp;<Tooltip textClass={"tooltip-link"} text={"🔍"} json={row.sellData} width={360} borderWidth={"1px"} position="right center" >
                </Tooltip >
            </>
        )

    }
    const tradesMapper = [
        { title: 'Id', field: 'id', formatter: tableHelpers.intFormatter },
        { title: 'Purch Date', field: 'purchaseDate', sorters: createDateSorter('purchaseDate'), formatter: purchaseDateFormatter },
        { title: 'Purch Price', field: 'purchasePrice', sorters: true, formatter: tableHelpers.moneyFormatter },
        { title: 'Sell Date', field: 'sellDate', sorters: createDateSorter('sellDate'), formatter: sellDateFormatter },
        { title: 'Sell Price', field: 'sellPrice', sorters: true, formatter: tableHelpers.moneyFormatter },
        { title: 'P/L', field: 'profitPercentage', sorters: true, formatter: tableHelpers.percentFormatter },
    ]

    return (
        <>
            <RichTable pageSize={10} data={getTrades()} mappers={tradesMapper} className="table-striped table-hover table-condensed" />
        </>
    );
}

export default AlertTrades;