import React, { useState, useEffect } from 'react';
import RichTable from '../components/richTable/RichTable.jsx'
import { createDateSorter, createDefaultSorter } from '../components/richTable/sorters.js'
import tableHelpers from '../helpers/TableHelpers.js'
import Tooltip from '../components/Tooltip.js'
import StickySettings from '../helpers/StickySettings.js'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import TwoStepConfirmationButton from "../components/TwoStepConfirmButton.js"
import ActionResult from '../components/ActionResult.js'

function TradingStrategies(props) {
    const [data, setData] = useState([]);
    const [algos, setAlgos] = useState([]);
    const [result, setResult] = useState(null)
    const [updates, setUpdates] = useState(0);
    const [triggeredAlerts, setTriggeredAlerts] = useState([])
    const [shouldFilterPlan, setShouldFilterPlan] = useState(StickySettings.getBool('alertsFilterByPlan'));
    const [shouldFilterActiveOnly, setShouldFilterActiveOnly] = useState(StickySettings.getBool('alertsFilterByActive'));
    const [shouldFilterUnassignedOnly, setShouldFilterUnassignedOnly] = useState(StickySettings.getBool('alertsFilterByUnassignedOnly'))
    const [planAlgos, setPlanAlgos] = useState([])
    const [isEvaluating, setIsEvaluating] = useState(false)
    const [view, setView] = useState('standard')

    useEffect(() => {
        (async function () {

            const algoText = await (await fetch(`/api/algos?planId=` + props.planId)).json();
            setPlanAlgos(algoText);

            let text = await (await fetch(`/api/strategyAlerts`)).json();
            if (text && Array.isArray(text)) {
                text.sort((a, b) => a.symbol < b.symbol ? -1 : 1)
            }
            setData(text);

            const algos = await (await fetch(`/api/algoChildren`)).json();
            setAlgos(algos);
        })();
    }, [updates, props.planId]);

    function getFilterAlerts() {
        let alerts = data;
        if (shouldFilterActiveOnly)
            alerts = alerts.filter(a => a.paused === 0);
        if (shouldFilterPlan) {
            alerts = alerts.filter(a => {
                return planAlgos.find(algo => algo.symbol === a.symbol && algo.name === a.algo_name) !== undefined
            })
        }
        if (shouldFilterUnassignedOnly) {
            alerts = alerts.filter(a => {
                return algos.find(algo => algo.symbol === a.symbol && algo.name === a.algo_name) === undefined
            })
        }
        return alerts;
    }

    function getAlerts() {

        const alerts = getFilterAlerts();

        return alerts.map(alert => {
            let alertStatus = triggeredAlerts.find(a => a.alertId === alert.idtrading_strategy_alerts);
            return {
                id: alert.idtrading_strategy_alerts,
                strategyId: alert.strategyId,
                strategyName: alert.name,
                algoName: alert.algo_name,
                symbol: alert.symbol,
                timeframe: alert.timeframe,
                config: alert.config,
                create_date: alert.create_date,
                paused: alert.paused,
                status: alert.paused ? "Paused" : "Active",
                last_triggered: alert.last_triggered,
                currentBarStatus: alertStatus ? alertStatus.status : null,
                currentPosition: alertStatus ? alertStatus.position : null,
                currentBarData: alertStatus ? alertStatus.currentBarData : null,
                pendingConfig: alert.pending_config
            }
        });
    }

    function getAlertsRisk() {
        const alerts = getFilterAlerts();

        return alerts.map(alert => {
            return {
                id: alert.idtrading_strategy_alerts,
                strategyId: alert.strategyId,
                strategyName: alert.name,
                algoName: alert.algo_name,
                symbol: alert.symbol,
                timeframe: alert.timeframe,
                config: alert.config,
                stopLossPercent: alert.config.stopLossPercent ? alert.config.stopLossPercent : null,
                stopLossAtr: alert.config.stopLossAtr ? alert.config.stopLossAtr : null,
                requiredSma: alert.config.requiredSma ? alert.config.requiredSma : null,
                maxSpikeInVolatility: alert.config.maxSpikeInVolatility ? alert.config.maxSpikeInVolatility : null,
                trailingStopAtrMultiplier: alert.config.trailingStopAtrMultiplier ? alert.config.trailingStopAtrMultiplier : null,
                fastExitOnVolatilitySpike: alert.config.fastExitOnVolatilitySpike ? alert.config.fastExitOnVolatilitySpike : null,
                maxDurationBars: alert.config.maxDurationBars ? alert.config.maxDurationBars : null,
                exitSma: alert.config.exitSma ? alert.config.exitSma : null
            }
        });
    }

    function shouldAllowEdits() {
        if (props.role === 3) return true;
        else return false;
    }

    async function handleDeleteAlert(alertId) {
        console.log("Delete alert", alertId);
        setResult('Deleting alert...');
        const url = "/api/strategyAlertDelete?alertId=" + alertId;
        const r = await (await fetch(url, { method: 'POST' })).json();
        setResult(r);
        setUpdates(updates + 1);
    }

    async function handleClone(alertId, suffix) {
        console.log(alertId, suffix);
        const url = "/api/strategyAlertClone?alertId=" + alertId + "&suffix=" + suffix;
        const r = await (await fetch(url, { method: 'POST' })).json();
        setResult(r);
        setUpdates(updates + 1);
    }

    async function handleResetAlertTriggered(alertId) {
        console.log("Resetting alert triggered", alertId);
        setResult('Resetting alert...');
        const url = "/api/strategyAlertUpdateTriggered?alertId=" + alertId + "&isTriggered=false";
        const r = await (await fetch(url, { method: 'POST' })).json();
        setResult(r);
        setUpdates(updates + 1);
    }

    async function handleCheckStatus(alertId) {
        console.log("handleCheckStatus", alertId);
        const url = "/api/strategyAlertEvaluator?alertId=" + alertId + "&currentBarOnly=1"
        const r = await (await fetch(url, {
            method: 'POST',
        })).json();
        console.log(r);
        let alerts = triggeredAlerts.map(a => a);
        let alert = {
            alertId: alertId,
            status: r.currentBarStatus,
            currentBarData: r.currentBarData
        }
        console.log("Pushing alert", alert);
        alerts.push(alert);
        setTriggeredAlerts(alerts);
    }

    function adminButtonsFormatter(cell, row) {
        return (
            <>
                {shouldAllowEdits() ? <TwoStepConfirmationButton id={row.id} text="❌" backgroundColor={'white'} onConfirmed={handleDeleteAlert} /> : null}
                {shouldAllowEdits() ? <>&nbsp;&nbsp;<TwoStepConfirmationButton id={row.id} text="Clone" input={"Suffix to append"} onConfirmed={handleClone} /></> : null}
                {shouldAllowEdits() && row.last_triggered ? <>&nbsp;&nbsp;<TwoStepConfirmationButton id={row.id} text="Reset" onConfirmed={handleResetAlertTriggered} /></> : null}
            </>
        )
    }

    function configureButtonFormatter(cell, row) {
        return (
            <>
                <Link className="bg-transparent" to={"/configureAlert/" + row.id}>Update</Link >
                {row.pendingConfig ? "⚠️" : null}
            </>
        )
    }

    function statusButtonFormatter(cell, row) {
        if (row.currentBarStatus === null)
            return (
                <>
                    <Link className="bg-transparent" onClick={() => handleCheckStatus(row.id)}>Check</Link>
                </>
            )
        else {
            return <>{row.currentBarStatus}<Tooltip text={" 🔍"} json={row.currentBarData} position={"left center"} /></>
        }
    }

    function configPopupFormatter(cell, row) {
        return (
            <>
                {row.strategyName}<Tooltip text={" 🔍"} json={row.config} />
            </>
        );
    }

    function algoPopupFormatter(cell, row) {

        let childrenDisplay = {};
        const children = algos.filter(a => a.name === row.algoName && a.symbol === row.symbol);
        let displayName = cell;
        let algoId = null;
        if (children) {
            children.forEach(c => {
                displayName = c.display_name;
                algoId = c.idalgos;
                childrenDisplay[c.plan_name] = c.percentage + "%";
            })
        }
        return (
            <>
                <Link to={"/algo/" + algoId}>{displayName}</Link><Tooltip json={childrenDisplay} text={" 🔍"} />
            </>
        )
    }

    async function handleEvaluateAllAlerts() {
        setIsEvaluating(true);
        console.log("handleEvaluateAllAlerts");
        const url = "/api/strategyAlertAllEvaluator"
        const r = await (await fetch(url, { method: 'POST' })).json();
        setTriggeredAlerts(r);
        setIsEvaluating(false);
    }

    async function handleDailyEvaluateAllAlerts() {
        setIsEvaluating(true);
        console.log("handleEvaluateAllAlerts");
        const url = "/api/dailyStrategyAlertEvaluator"
        const r = await (await fetch(url, { method: 'POST' })).json();
        setTriggeredAlerts(r);
        setIsEvaluating(false);
    }

    async function handleEvaluateFilteredAlerts() {
        console.log("handleEvaluateFilteredAlerts");
        setIsEvaluating(true);
        let alerts = getFilterAlerts();
        let alertIds = alerts.map(a => a.idtrading_strategy_alerts)
        const url = "/api/strategyAlertAllEvaluator?alertIds=" + alertIds
        console.log(url);
        const r = await (await fetch(url, { method: 'POST' })).json();
        setTriggeredAlerts(r);
        setIsEvaluating(false);
    }

    function onPlanFilterSelected() {
        console.log("onPlanFilterSelected");
        StickySettings.setBool('alertsFilterByPlan', !shouldFilterPlan);
        setShouldFilterPlan(!shouldFilterPlan);
    }

    function onUnassignedFilterSelected() {
        StickySettings.setBool('alertsFilterByUnassignedOnly', !shouldFilterUnassignedOnly);
        setShouldFilterUnassignedOnly(!shouldFilterUnassignedOnly);
    }

    function onActiveFilterSelected() {
        console.log("onActiveFilterSelected");
        StickySettings.setBool('alertsFilterByActive', !shouldFilterActiveOnly);
        setShouldFilterActiveOnly(!shouldFilterActiveOnly);
    }

    const alertsTableMappers = [
        { title: 'Algo Name', field: 'algoName', formatter: algoPopupFormatter, sorters: [createDefaultSorter('algoName'), createDefaultSorter('symbol')], filter: 'text' },
        { title: 'Symbol', field: 'symbol', sorters: true, filter: 'text' },
        { title: 'Strategy', field: 'strategyName', formatter: configPopupFormatter, sorters: true, filter: 'text', hidden: props.mobileView },
        { title: 'Current Bar', field: 'currentBarStatus', formatter: statusButtonFormatter, sorters: true },
        { title: 'Configure', field: 'id', formatter: configureButtonFormatter },
        { title: 'Status', field: 'status', sorters: true, hidden: props.mobileView },
        { title: 'Last Triggered', field: 'last_triggered', formatter: tableHelpers.dateFormmatter, sorters: createDateSorter('last_triggered'), hidden: props.mobileView },
        { title: 'Manage', field: 'id', formatter: adminButtonsFormatter, sorters: true, hidden: !shouldAllowEdits() || props.mobileView }
    ]

    return (
        <>
            {
                result ?
                    <>
                        <hr></hr>
                        <h3>Result</h3>

                        {JSON.stringify(result, null, '\t')}
                    </>
                    :
                    null
            }

            <hr></hr>
            <h3>Alerts</h3>
            <div className="sameline">
                <input type='checkbox' checked={shouldFilterPlan} onClick={() => onPlanFilterSelected()} />Plan only
                &nbsp;&nbsp;&nbsp;&nbsp;<input type='checkbox' checked={shouldFilterActiveOnly} onClick={() => onActiveFilterSelected()} />Active only
                &nbsp;&nbsp;&nbsp;&nbsp;<input type='checkbox' checked={shouldFilterUnassignedOnly} onClick={() => onUnassignedFilterSelected()} />Unassigned only
                &nbsp;&nbsp;&nbsp;&nbsp;<button disabled={isEvaluating} class="btn btn-primary" onClick={() => handleEvaluateFilteredAlerts()}>{isEvaluating ? "Please wait..." : "Evaluate Filtered"}</button>
                &nbsp;&nbsp;View:&nbsp;
                <Dropdown onSelect={(e) => setView(e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {view}
                    </Dropdown.Toggle>

                    <Dropdown.Menu  >
                        <Dropdown.Item as='viewId' eventKey={'standard'} value={'standard'}>Standard</Dropdown.Item>
                        <Dropdown.Item as='viewId' eventKey={'risk'} value={'risk'}>Risk</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <br></br>

            {view === "standard" ?
                <RichTable data={getAlerts()} mappers={alertsTableMappers} className="table-striped table-hover table-condensed" />
                :
                null
            }
            {view === "risk" ?
                <RichTable data={getAlertsRisk()} className="table-striped table-hover table-condensed" mappers={
                    [
                        { title: 'Algo Name', field: 'algoName', formatter: algoPopupFormatter, sorters: [createDefaultSorter('algoName'), createDefaultSorter('symbol')], filter: 'text' },
                        { title: 'Symbol', field: 'symbol', sorters: true, filter: 'text' },
                        { title: 'Strategy', field: 'strategyName', formatter: configPopupFormatter, sorters: true, filter: 'text', hidden: props.mobileView },
                        { title: 'Stop Loss %', field: 'stopLossPercent', formatter: tableHelpers.percentFormatterOneDecimal, sorters: true },
                        { title: 'Stop Loss ATR', field: 'stopLossAtr', formatter: tableHelpers.decimalFormatter, sorters: true },
                        { title: 'TStop ATR', field: 'trailingStopAtrMultiplier', formatter: tableHelpers.decimalFormatter, sorters: true },
                        { title: 'Exit SMA', field: 'exitSma', formatter: tableHelpers.intFormatter, sorters: true },
                        { title: 'Vol Fast Exit', field: 'fastExitOnVolatilitySpike', formatter: tableHelpers.percentFormatterNoDecimals, sorters: true },
                        { title: 'Max Duration', field: 'maxDurationBars', formatter: tableHelpers.intFormatter, sorters: true },
                        { title: 'Required SMA', field: 'requiredSma', formatter: tableHelpers.intFormatter, sorters: true },
                        { title: 'Vol Max Entry', field: 'maxSpikeInVolatility', formatter: tableHelpers.percentFormatterOneDecimal, sorters: true },
                    ]
                } />
                :
                null
            }

            <ActionResult result={result} />

            <br></br>
            <button class="btn btn-primary" disabled={isEvaluating} onClick={() => handleEvaluateAllAlerts()}>{isEvaluating ? "Please Wait..." : "Evaluate All"}</button>
            <br></br>
            <br></br>
            {shouldAllowEdits() ?
                <>
                    <button class="btn btn-danger" disabled={isEvaluating} onClick={() => handleDailyEvaluateAllAlerts()}>{isEvaluating ? "Please Wait..." : "Generate Daily Trades"}</button>
                    <br></br>
                    <br></br>
                </>
                :
                null
            }
        </>
    );
}

export default TradingStrategies;