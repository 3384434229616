import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import RichTable from '../components/richTable/RichTable.jsx'
import tableHelpers from '../helpers/TableHelpers.js'
import PlanScorecard from './PlanScorecard.js'
import Tooltip from '../components/Tooltip.js';
import CircularProgress from '@mui/material/CircularProgress';
import ActionResult from '../components/ActionResult.js';
import TwoStepConfirmationButton from "../components/TwoStepConfirmButton.js"
import Dropdown from 'react-bootstrap/Dropdown';
import SaveIcon from '@mui/icons-material/Save';
import Drawer from '@mui/material/Drawer';
import AlgoStats from '../algos/AlgoStats.js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

function Algos(props) {
    const [data, setData] = useState([]);
    const [backtestResults, setBacktestResults] = useState(null);
    const [lastBacktestResults, setLastBacktestResults] = useState(null)
    const [isTestRunning, setIsTestRunning] = useState(false);
    const [focusAlgoId, setFocusAlgoId] = useState(null);
    const [result, setResult] = useState(null);
    const [statYears, setStatYears] = useState(3);
    const [updates, setUpdates] = useState(0);
    const [unsavedChanges, setUnsavedChanges] = useState(0);
    const [hideAlgos, setHideAlgos] = useState(true)

    useEffect(() => {
        (async function () {
            let text = await (await fetch(`/api/algos?planId=` + props.planId + `&years=` + statYears)).json();
            console.log(text);
            setData(text);
        })();
    }, [props.planId, props.itemsChanged, statYears, updates]);

    async function handleSoftRemove(algoId) {
        let tempData = JSON.parse(JSON.stringify(data));
        tempData = tempData.filter(algo =>
            algo.algoId !== algoId
        );
        console.log(tempData);
        setData(tempData);
        setUnsavedChanges(unsavedChanges + 1);
    }

    async function updateAllocation(algoId, diplayName, newPercentage) {
        console.log("handleSubmitAllocationChange", algoId, newPercentage)
        const algo = data.find(algo => algo.idalgos === algoId);
        if (algo && props.allowEdit()) {
            //const url = `/api/updatePlanAlgoAllocation?planId=` + props.planId + `&algoId=` + algoId + `&percentage=` + newPercentage;
            //await (await fetch(url, { method: 'POST' })).json();
            //setResult({ success: diplayName + " updated to " + newPercentage });
        }
    }

    async function updateAllAllocations() {
        console.log("updateAllAllocations")

        let allocations = {};
        data.forEach(allocation => {
            if (allocation.percentage && Number(allocation.percentage)) {
                allocations[Number(allocation.idalgos)] = Number(allocation.percentage);
            }
        })
        console.log("allocations", allocations);

        const url = `/api/updatePlanAllocations?planId=` + props.planId;
        let result = await (await fetch(url, { method: 'POST', body: JSON.stringify(allocations) })).json();
        console.log(result);
        setResult(result);
        setUpdates(updates + 1);
        setUnsavedChanges(0);
        props.onAllocationUpdated();
    }

    async function onUpdateAlgo(entrySpeed, algoId) {
        console.log("onUpdateAlgo", algoId, entrySpeed);

        if (props.allowAlgoUpdates()) {
            const url = `/api/algoUpdate?algoId=` + algoId + `&entrySpeed=` + entrySpeed;
            let result = await (await fetch(url, { method: 'POST' })).json();
            setResult(result);
        }

        const newData = data.map((algo) => {
            if (algo.idalgos === algoId) {
                return { ...algo, entry_speed: entrySpeed };
            }
            return algo;
        });

        setData(newData);
    }

    function onAllocationUpdated(algoId, event) {
        console.log("onAllocationUpdated", algoId, Number(event.target.value))
        const newData = data.map((algo) => {
            if (algo.idalgos === algoId) {
                return { ...algo, percentage: Number(event.target.value) };
            }
            return algo;
        });

        setData(newData);
    }

    function onAllocationUpdatedSet(algoId, newValue) {
        console.log("onAllocationUpdatedSet", algoId, newValue)
        const newData = data.map((algo) => {
            if (algo.idalgos === algoId) {
                const newPercentage = newValue;
                if (newPercentage >= 0 && newPercentage <= 100) {
                    updateAllocation(algoId, algo.display_name, newPercentage);
                    setUnsavedChanges(unsavedChanges + 1);
                    return { ...algo, percentage: newPercentage };
                } else {
                    return algo;
                }
            }
            return algo;
        });

        setData(newData);
    }

    function onAllocationIncremented(algoId, value) {
        const newData = data.map((algo) => {
            if (algo.idalgos === algoId) {
                const newPercentage = algo.percentage + value;
                if (newPercentage >= 0 && newPercentage <= 100) {
                    updateAllocation(algoId, algo.display_name, newPercentage);
                    setUnsavedChanges(unsavedChanges + 1);
                    return { ...algo, percentage: newPercentage };
                } else {
                    return algo;
                }
            }
            return algo;
        });

        setData(newData);
        console.log("onAllocationIncremented", algoId, value)
    }

    function getAlgos() {
        return data.map(a => {
            return {
                id: a.idalgos,
                display_name: a.display_name,
                name: a.name,
                symbol: a.symbol,
                trade_symbol: a.trade_symbol,
                percentage: a.percentage,
                algo_type: a.algo_type,
                entry_speed: a.entry_speed,
                count: a.count,
                avg_duration: a.avg_duration,
                winning_percentage: a.winning_percentage,
                max_drawdown: a.max_drawdown,
                net_max_drawdown: a.max_drawdown * a.percentage / 100,
                avg_profit: a.avg_profit,
                avg_win: a.avg_win,
                avg_loss: a.avg_loss,
                max_win: a.max_win,
                max_loss: a.max_loss,
                robustness_score: a.robustness_score,
                live_vs_backtest: a.live_vs_backtest
            }
        })
    }

    function editBoxFormatter(cell, row) {
        return (
            <>
                {props.allowEdit() ? <><Link className="plus-minus-button" onClick={() => onAllocationIncremented(row.id, -1)}>⬇️</Link>&nbsp;&nbsp;</> : null}
                <input onFocus={() => setFocusAlgoId(row.id)} onBlur={() => { setFocusAlgoId(null) }} type="text" size="4" id={row.idalgos} name={row.id} value={cell} onKeyDown={(event) => handleKeyDown(event, cell)} onChange={(e) => onAllocationUpdated(row.id, e)}></input>
                &nbsp; {props.allowEdit() ? <Link className="plus-minus-button" onClick={() => onAllocationIncremented(row.id, 1)}>⬆️</Link> : null}
            </>
        )
    }

    function entrySpeedFormatter(cell, row) {
        return (
            <>
                {props.allowAlgoUpdates() ?
                    <>
                        <Dropdown onSelect={(e) => onUpdateAlgo(e, row.id)}>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                {cell}
                            </Dropdown.Toggle>

                            <Dropdown.Menu  >
                                <Dropdown.Item as='speed' eventKey={'fast'} value={'fast'}>fast</Dropdown.Item>
                                <Dropdown.Item as='speed' eventKey={'medium'} value={'medium'}>medium</Dropdown.Item>
                                <Dropdown.Item as='speed' eventKey={'slow'} value={'slow'}>slow</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                    :
                    <>{cell}</>

                }
            </>
        );
    }

    function handleKeyDown(event, value) {
        console.log("Key press", event.key);
        console.log("Focus algo", focusAlgoId);
        if (event.key === 'ArrowUp') {
            if (focusAlgoId != null) {
                onAllocationIncremented(focusAlgoId, 1);
            }
        } else if (event.key === 'ArrowDown') {
            if (focusAlgoId != null) {
                onAllocationIncremented(focusAlgoId, -1);
            }
        } else if (Number.isInteger(value)) {
            onAllocationUpdatedSet(focusAlgoId, Number(value))
        }
    }

    const onAddAlgoToPlan = async (algoId) => {
        console.log("onAddAlgoToPlan", algoId);

        await (await fetch(`/api/addAlgoToPlan?planId=` + props.planId + `&algoId=` + algoId)).json();

        const algo = await (await fetch(`/api/algo?algoId=` + algoId)).json();
        let algoInfo = algo.algo[0];
        console.log("adding", algo);
        let tempData = JSON.parse(JSON.stringify(data));
        let stats = algo.stats.find(stats => stats.years === statYears)
        tempData.push({
            idalgos: algoId,
            algoId: algoId,
            display_name: algoInfo.display_name,
            name: algoInfo.name,
            symbol: algoInfo.symbol,
            trade_symbol: algoInfo.trade_symbol,
            percentage: 0,
            algo_type: algoInfo.algo_type,
            entry_speed: algoInfo.entry_speed,
            count: stats ? stats.count : 0,
            avg_duration: stats ? stats.avg_duration : 0,
            winning_percentage: stats ? stats.winning_percentage : 0,
            max_drawdown: stats ? stats.max_drawdown : 0,
            net_max_drawdown: 0,
            avg_profit: stats ? stats.avg_profit : 0,
            avg_win: stats ? stats.avg_win : 0,
            avg_loss: stats ? stats.avg_loss : 0,
            max_win: stats ? stats.max_win : 0,
            max_loss: stats ? stats.max_loss : 0,
            robustness_score: algoInfo.robustness_score,
            live_vs_backtest: algoInfo.live_vs_backtest
        })
        setData(tempData);
    }

    async function onAlgoAdded(algoId) {
        console.log("onAlgoAdded", algoId);
        if (props.allowEdit()) {
            await onAddAlgoToPlan(algoId);
        } else {
            setResult({ error: "Not allowed" })
        }
    }

    function addRemoveFormatter(cell, row) {
        return (
            <>
                {props.allowEdit() ?
                    <button class="btn btn-secondary" onClick={() => handleSoftRemove(row.id)} type="button">❌</button>
                    : null}
            </>
        )
    }

    function algoLinkFormatter(cell, row) {
        let algoid = row.id;
        return (
            <>
                <Link className="bg-transparent" to={"/algo/" + algoid}>{cell}</Link>
                &nbsp;<Tooltip text={" 🔍"} json={row} position={"right center"} />
            </>
        )
    }

    async function runBacktest() {
        if (!isTestRunning) {
            let fullStartDate = new Date(2014, 0, 1)
            let fullEndDate = new Date()
            let startAmount = 100000;
            let allocations = {};
            data.forEach(allocation => {
                if (allocation.percentage && Number(allocation.percentage)) {
                    allocations[Number(allocation.idalgos)] = Number(allocation.percentage);
                }
            })
            console.log("allocations", allocations);
            const backtestTradesUrl = `/api/backtestRunComplete?planId=` + props.planId +
                "&startDate=" + fullStartDate.toLocaleDateString() +
                "&endDate=" + fullEndDate.toLocaleDateString() +
                "&startAmount=" + startAmount +
                "&activeTradesOnly=0" +
                "&liveTradesOnly=0" +
                "&deleteResults=0" +
                "&allocationStrategyId=4" +
                "&isOfficial=0";

            console.log("url", backtestTradesUrl);

            setIsTestRunning(true);
            setLastBacktestResults(JSON.parse(JSON.stringify(backtestResults)));
            setBacktestResults(null);

            let text = await fetch(backtestTradesUrl, { method: 'POST', body: JSON.stringify(allocations) });
            console.log("response", text);
            let results = await text.json();
            console.log("results", results);

            setBacktestResults(results);
            setIsTestRunning(false);
        }
    }

    return (
        <>
            <ActionResult result={result} />

            <div className='sameline'>
                Stats for Trades:
                <Dropdown width="150" onSelect={(e) => setStatYears(e)}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {statYears} Years
                    </Dropdown.Toggle>

                    <Dropdown.Menu  >
                        <Dropdown.Item as='a' eventKey={0} value={0}>Live</Dropdown.Item>
                        <Dropdown.Item as='g' eventKey={1} value={1}>1 Year</Dropdown.Item>
                        <Dropdown.Item as='b' eventKey={2} value={2}>2 Years</Dropdown.Item>
                        <Dropdown.Item as='e' eventKey={3} value={3}>3 Years</Dropdown.Item>
                        <Dropdown.Item as='c' eventKey={5} value={5}>5 Years</Dropdown.Item>
                        <Dropdown.Item as='d' eventKey={10} value={10}>10 Years</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>


            <RichTable data={getAlgos()} className="table-striped table-hover table-condensed fixed" mappers={[
                { title: 'Id', field: 'id', formatter: tableHelpers.intFormatter, sorters: true, width: '40px', hidden: props.mobileView },
                { title: 'Algo', field: 'display_name', formatter: algoLinkFormatter, sorters: true, filter: 'text' },
                { title: 'Symbol', field: 'symbol', sorters: true, hidden: props.mobileView, filter: 'text' },
                { title: 'Trade Sym', field: 'trade_symbol', sorters: true, filter: 'text' },
                { title: 'Allocation', field: 'percentage', formatter: editBoxFormatter, sorters: true, width: '120px' },
                { title: 'Type', field: 'algo_type', sorters: true, filter: 'choice', hidden: props.mobileView },
                { title: 'Entry Speed', field: 'entry_speed', sorters: true, filter: 'choice', hidden: props.mobileView, formatter: entrySpeedFormatter },
                { title: 'Robustness', field: 'robustness_score', formatter: tableHelpers.decimalFormatter, sorters: true, hidden: props.mobileView },
                { title: 'Days', field: 'avg_duration', formatter: tableHelpers.intFormatter, sorters: true, width: '60px', hidden: props.mobileView },
                { title: 'Win %', field: 'winning_percentage', formatter: tableHelpers.percentFormatter, sorters: true, width: '70px', hidden: props.mobileView },
                { title: 'Max DD', field: 'max_drawdown', formatter: tableHelpers.percentFormatter, sorters: true, width: '70px', hidden: props.mobileView },
                { title: 'Net Max DD', field: 'net_max_drawdown', formatter: tableHelpers.percentFormatter, sorters: true, width: '70px', hidden: props.mobileView },
                { title: 'P/L', field: 'avg_profit', formatter: tableHelpers.percentFormatter, sorters: true, width: '70px', hidden: props.mobileView },
                { title: '#', field: 'count', formatter: tableHelpers.intFormatter, sorters: true, width: '50px', hidden: props.mobileView },
                { title: 'Manage', field: 'id', formatter: addRemoveFormatter, sorters: true },
            ]} />

            <div className='addalgo-button'>
                <Link onClick={() => setHideAlgos(!hideAlgos)}>
                    <AddCircleIcon sx={{ fontSize: 40 }} />
                </Link>
            </div>

            <div className='runtest-button'>
                <Tooltip textClass="big-link" onOpen={() => runBacktest()} triggerJsx={<QueryStatsIcon sx={{ fontSize: 40 }} />} text="Run test..." position="bottom left" width="800px" >
                    <table width="800px">
                        <tr>
                            <th>Latest Run</th>
                            <th width="50%">Previous Run</th>
                        </tr>
                        <tr>
                            <td>
                                {backtestResults ?
                                    <PlanScorecard backtestResults={backtestResults}></PlanScorecard>
                                    :
                                    <CircularProgress />
                                }
                            </td>
                            <td>
                                {lastBacktestResults ?
                                    <PlanScorecard backtestResults={lastBacktestResults}></PlanScorecard>
                                    :
                                    <>None</>
                                }
                            </td>
                        </tr>
                    </table>
                </Tooltip>
            </div>

            {unsavedChanges && props.allowEdit() ?
                <div className='saveAllocations-button'>
                    <TwoStepConfirmationButton id={0} text={"💾 Save changes to floppy (" + unsavedChanges + ")"} onConfirmed={updateAllAllocations}
                        triggerJsx={<>
                            <SaveIcon sx={{ fontSize: 40 }} />
                            <br></br>
                            SAVE CHANGES
                        </>
                        } />
                </div>
                : null}

            <Drawer open={!hideAlgos} onClose={() => setHideAlgos(true)}
                PaperProps={{
                    sx: { width: "90%" },
                }}
            >
                <div style={{ padding: "20px" }}>
                    <div>
                        <h2>All Algos</h2>
                        <AlgoStats showAll={true} defaultYears={10} planId={props.planId} mobileView={props.mobileView} onAlgoAction={props.allowEdit() ? onAlgoAdded : null} />
                    </div>

                    <div className='addalgo-button'>
                        <Link onClick={() => setHideAlgos(!hideAlgos)}>
                            <CheckCircleIcon sx={{ fontSize: 40 }} />
                        </Link>
                    </div>
                </div>

            </Drawer>
        </>
    );
}

export default Algos;