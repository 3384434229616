import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

function TradeWizard(props) {

    const [accountValue, setAccountValue] = useState(0)
    //const [result, setResult] = useState('')
    const [step, setStep] = useState(0);
    const [trades, setTrades] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    //const [updates, setUpdates] = useState(0);

    useEffect(() => {
        (async function () {

            const url = `/api/unplacedUserTrades?userId=` + props.userId + `&planId=` + props.planId;
            const tradesJson = await (await fetch(url)).json();
            let mergedTrades = mergeTrades(tradesJson);
            setTrades(mergedTrades);
            setIsLoading(false);

        })();
    }, [props.planId, props.userId]);


    function mergeTrades(trades) {
        return trades;
    }

    function handleAccountValueNextClicked() {
        if (accountValue > 0) {
            setStep(1);
        }
    }

    function renderGetAccountValue() {
        console.log("trades", trades);
        if (trades.length > 0)
            return (
                <>
                    You have {trades.length} trades to confirm.
                    <br></br>

                    Current Account Value: <input type="text" autoCorrect="off" size="10" defaultValue="0" onChange={(e) => setAccountValue(e.target.value)}></input>
                    &nbsp;<button class="btn btn-secondary" onClick={(e) => handleAccountValueNextClicked()}>Show Trades</button>
                </>
            )
        else
            return (
                <>
                    No trades to place. Check back later!
                </>
            );
    }

    function renderTrades() {

        return trades.map(trade => {
            let shares = Math.floor(accountValue * trade.allocation / 100 / trade.price);
            trades.shares = shares;
            return (<>
                {trade.trade_symbol} {trade.allocation}% or {shares} shares<br></br>
            </>

            );
        });
    }

    /*
    async function onTradesConfirmed() {
        let tradeString = "";
        trades.forEach(trade => {
            tradeString += trade.idplan_trades + "&" + trade.quantity
        })
        const url = `/api/placeUserTrades?userId=` + props.userId + `&planId=` + props.planId;
        const result = await (await fetch(url)).json();
        setResult(result);
        setUpdates(updates + 1);
    }
    */

    function renderDone() {

        return (
            <>
                All done, great job!
                <br></br>
                <br></br>
                <Link to={`/plan`} className="bg-transparent" >View Positions</Link>
            </>
        )
    }

    function handleEditClicked() {

    }

    function renderButtons() {
        return (
            <>
                <button class="btn btn-secondary" onClick={(e) => handleEditClicked()}>Edit quantities</button>
                &nbsp;&nbsp;<button class="btn btn-secondary" onClick={(e) => handleTradesConfirmed()}>All orders are in!</button>
            </>
        );
    }

    function handleTradesConfirmed() {
        setStep(2);
    }

    return (

        <center>
            <div>
                {!isLoading ?
                    <>
                        {step === 0 ? renderGetAccountValue() : null}
                        {step === 1 ? renderTrades() : null}
                        {step === 1 ? renderButtons() : null}
                        {step === 2 ? renderDone() : null}
                    </>
                    :
                    <div class="empty-page" >Loading...</div>
                }
            </div >
        </center>
    );
}

export default TradeWizard;